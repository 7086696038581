import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import './index.css';
import reportWebVitals from './reportWebVitals';
import Login from './login';
import ScrollToTop from './components/scrollToTop';
import Home from './components/routes/home/home';
import Dashboard from './components/routes/dashboard';
import { AuthProvider } from './utils/contexts/authContext';
import PrivateRoute from './utils/privateRoute';
import ConstructionPage from './components/screenAlerts/constructionPage';
import Movement from './components/routes/movement';
import Profile from './components/routes/profile/profile';

const root = createRoot(document.getElementById('root'));

root.render(
  <Router>
    <ScrollToTop>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/home" element={<PrivateRoute element={Home} />} />
          <Route path="/dashboard" element={<PrivateRoute element={Dashboard} />} />
          <Route path="/add-movement" element={<PrivateRoute element={Movement} />} />
          <Route path="/profile" element={<PrivateRoute element={Profile} />} />
          <Route path="*" element={<ConstructionPage />} />
        </Routes>

        <Toaster
          position="top-right"
          toastOptions={{
            style: {
              maxWidth: "90%",
              userSelect: "none",
              borderRadius: "lg",
              padding: "4px 16px",
              borderWidth: "1px",
              borderColor: "#100F0F",
              color: "white",
              fontWeight: "semibold",
              boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
            },
            success: {
              duration: 2600,
              style: {
                background: "#4CAF50",
              },
            },
            error: {
              duration: 2600,
              style: {
                background: "#F24C3D",
              },
            }
          }}
        />
      </AuthProvider>
    </ScrollToTop>
  </Router>
);

reportWebVitals();
