import { useEffect, useState } from "react";

const ApexChart = ({
  id,
  categories,
  stroke,
  dashed,
  isHorizontal,
  isSlope,
  series,
  labels,
  type,
  onlyChart,
  colors,
  curve,
  height,
  hasLegend,
  title,
  subtitle,
  titleColor,
}) => {
  const [Chart, setChart] = useState(null);
  const hasType = typeof type !== "undefined";

  useEffect(() => {
    import("react-apexcharts").then((mod) => {
      setChart(() => mod.default);
    });
  }, []);

  return (
    hasType &&
    Chart && (
      <Chart
        options={{
          title: {
            text: title && title,
            offsetX: 8,
            offsetY: 0,
            style: {
              fontSize: "24px",
              fontFamily: "Poppins, sans-serif",
              color: titleColor ? titleColor : "rgba(255,255,255,1)",
              fontWeight: "600",
            },
          },
          subtitle: {
            text: subtitle && subtitle,
            offsetX: 8,
            offsetY: 28,

            style: {
              fontSize: "14px",
              fontFamily: "Poppins, sans-serif",
              color: "rgba(255,255,255,0.6)",
              fontWeight: "400",
            },
          },
          chart: {
            id,
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
            sparkline: {
              enabled: hasLegend ? false : true,
            },
          },
          dataLabels: {
            enabled: type === "bar" || type === "line" ? false : false,
          },
          labels: labels ? labels : "",
          xaxis: {
            categories: categories || "",
            labels: {
              // show: !onlyChart,
              show: false, // Oculta os rótulos no eixo x
              style: {
                colors: "#FFFFFF", // Altera a cor dos labels no eixo y para branco
              },
            },
            axisBorder: {
              show: hasLegend ? true : false, // Oculta o tracejado inferior horizontal no eixo x
              borderColor: "rgba(255, 255, 255, 0.2)",
            },
            axisTicks: {
              show: hasLegend ? true : false, // Oculta o tracejado inferior vertical no eixo x
            },
          },
          yaxis: {
            labels: {
              show: !onlyChart, // Oculta os rótulos no eixo Y se necessário
              style: {
                colors: "#FFFFFF", // Cor dos labels no eixo Y
                fontSize: "12px",
                fontFamily: "Poppins, sans-serif",
              },
              formatter: function (value) {
                const numero = parseFloat(value); // Tenta converter para número
                return isNaN(numero) ? value : Math.floor(numero); // Exibe inteiro ou valor original
              },
            },
          },
          grid: {
            show: true, // Oculta as linhas de grade
            borderColor: "rgba(255, 255, 255, 0.0)", // Cor da borda das células da grade
          },
          colors: colors !== undefined ? colors : ["#FFFFFF"],
          stroke: {
            width: stroke ? stroke : 3, // Largura da linha
            curve: !curve ? "smooth" : curve, // Tipo de curva da linha ("straight" / "smooth" / "monotoneCubic" / "stepline")
            colors: type !== "pie" ? colors : colors ? ["#28293D"] : "#1ce3c7",
            dashArray: dashed ? dashed : [0],
          },
          plotOptions: {
            bar: {
              horizontal: isHorizontal,
              distributed: true,
            },
            line: {
              isSlopeChart: isSlope,
            },
            pie: {
              expandOnClick: false,
              dataLabels: {
                formatter: function (val, opts) {
                  return opts.w.globals.labels[opts.seriesIndex] + val + "%";
                },
              },
            },
          },
          tooltip: {
            style: {
              fontSize: "12px",
              fontFamily: "Poppins, sans-serif",
            },
            y: {
              formatter: function (value) {
                return type === "pie"
                  ? value + "%"
                  : value.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    });
              },
            },
          },
          legend: {
            show: !onlyChart && !isHorizontal && hasLegend ? true : false,
            position: "bottom",
            fontSize: "12px",
            fontFamily: "Poppins, sans-serif",
            fontWeight: 400,
            offsetX: 0,
            offsetY: 0,
            labels: {
              colors: "#FFFFFF",
            },
            markers: {
              size: 5,
              shape: undefined,
              strokeWidth: 0,
              fillColors: undefined,
              customHTML: undefined,
              onClick: undefined,
              offsetX: -2,
              offsetY: 0,
            },
            itemMargin: {
              horizontal: 5,
              vertical: 0,
            },
            onItemClick: {
              toggleDataSeries: true,
            },
            onItemHover: {
              highlightDataSeries: true,
            },
          },
        }}
        series={series}
        type={type}
        width="100%"
        height={height !== undefined ? height : "100%"}
      />
    )
  );
};

export default ApexChart;
