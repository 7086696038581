import React from "react";
import ApexChart from "../../../ApexChart";
import ChartContainer from "./chartContainer";

// Função para obter o mês e ano de uma data no formato "YYYY-MM"
function formatDateToMonthYear(date) {
  const parsedDate = new Date(date);
  const year = parsedDate.getFullYear();
  const month = String(parsedDate.getMonth() + 1).padStart(2, "0"); // Janeiro é 0, então somamos 1
  return `${year}-${month}`; // Ex: "2024-10"
}

// Função para calcular a média mensal por categoria, excluindo o mês atual
function computeMonthlyAverage(transactions) {
  const now = new Date();
  const currentMonth = now.getMonth();
  const currentYear = now.getFullYear();

  const expensesByMonth = {};

  transactions.forEach((transaction) => {
    if (transaction?.type === "Saída") {
      const parsedDate = new Date(transaction.date);
      const month = parsedDate.getMonth();
      const year = parsedDate.getFullYear();

      // Excluir o mês atual
      if (year === currentYear && month >= currentMonth) return;

      const monthYear = formatDateToMonthYear(transaction.date);
      const category = transaction.financialType || "Outros";

      if (!expensesByMonth[monthYear]) expensesByMonth[monthYear] = {};
      if (!expensesByMonth[monthYear][category])
        expensesByMonth[monthYear][category] = 0;

      expensesByMonth[monthYear][category] +=
        parseFloat(transaction.value) || 0;
    }
  });

  const categoryAverages = {};
  const totalMonths = Object.keys(expensesByMonth).length;

  for (const month in expensesByMonth) {
    for (const category in expensesByMonth[month]) {
      if (!categoryAverages[category]) categoryAverages[category] = 0;
      categoryAverages[category] += expensesByMonth[month][category];
    }
  }

  // Calcular a média por categoria
  for (const category in categoryAverages) {
    categoryAverages[category] /= totalMonths;
  }

  return categoryAverages;
}

// Função para calcular os gastos do mês atual por categoria
function computeCurrentMonthExpenses(transactions) {
  const now = new Date();
  const currentMonth = String(now.getMonth() + 1).padStart(2, "0");
  const currentYear = now.getFullYear();

  const currentMonthYear = `${currentYear}-${currentMonth}`; // Formato YYYY-MM

  // Filtrar transações do tipo "Saída" e do mês atual
  const filteredTransactions = transactions.filter((transaction) => {
    return (
      transaction.type === "Saída" &&
      transaction?.date.startsWith(currentMonthYear)
    );
  });

  const currentMonthExpenses = {};

  filteredTransactions.forEach((transaction) => {
    const category = transaction.financialType || "Outros";
    const value = parseFloat(transaction.value) || 0;

    if (!currentMonthExpenses[category]) {
      currentMonthExpenses[category] = 0;
    }
    currentMonthExpenses[category] += value;
  });

  return currentMonthExpenses;
}

export default function ExpenseForecast({ movements }) {
  const expenseCategories = [
    "Alimentação",
    "Lazer",
    "Saúde",
    "Vestuário",
    "Mensalidades",
    "Transporte",
    "Pets",
    "Crédito",
    "Outros",
  ];

  const categoryAverages = computeMonthlyAverage(movements);
  const currentMonthExpenses = computeCurrentMonthExpenses(movements);

  const forecastData = expenseCategories.map(
    (category) => categoryAverages[category] || 0
  );
  const currentExpensesData = expenseCategories.map(
    (category) => currentMonthExpenses[category] || 0
  );

  // Calcula o total de previsão e o total gasto até agora
  const totalForecast = forecastData.reduce((acc, val) => acc + val, 0);
  const totalCurrentExpenses = Object.values(currentMonthExpenses).reduce(
    (acc, val) => acc + val,
    0
  );

  const seriesData = expenseCategories.map((category, index) => ({
    x: category,
    y: currentExpensesData[index],
    goals: [
      {
        name: "Previsto",
        value: forecastData[index],
        strokeHeight: 4,
        strokeWidth: 8,
        strokeColor: "#1ce3c7",
        strokeLineCap: "round",
      },
    ],
  }));

  return (
    <ChartContainer title={"Previsão de Gastos"}>
      <div className="flex flex-col text-center text-sm mt-2">
        <p className="text-white">
          Previsão total para o mês:{" "}
          <span className="text-xl font-bold text-easyGreen">
            R${totalForecast.toFixed(2)}
          </span>
        </p>

        <p className="text-white">
          Total gasto até o momento:{" "}
          <span className="text-xl font-bold text-red-500">
            R${totalCurrentExpenses.toFixed(2)}
          </span>
        </p>
      </div>

      <div className="w-full -mt-6 md:-mt-0">
        <ApexChart
          series={[{ name: "Gasto Atual", data: seriesData }]}
          type="bar"
          height="230px"
          hasLegend
          colors={[
            "#ffbd3d",
            "#00cf44",
            "#993399",
            "#ec0000",
            "#0A6847",
            "#FC4100",
            "#5a9cff",
            "#2D26B2",
            "#FF5BAE",
          ]}
        />
      </div>
    </ChartContainer>
  );
}
