import { useState } from "react";
import ModalTable from "../modalTable";
import ChartContainer from "../../valueProjections/balanceAndMovements/components/chartContainer";
import Table from "./table";

export default function TableMovements({ movements, fetch, dual }) {
  const [openEntryModal, setOpenEntryModal] = useState(false);

  const filteredMovements =
    movements && movements.length > 0
      ? movements.sort((a, b) => {
          const dateComparison = new Date(b.date) - new Date(a.date);
          if (dateComparison !== 0) {
            return dateComparison;
          }
          return Number(b.timeStamp) - Number(a.timeStamp);
        })
      : [];

  return (
    <ChartContainer
      title={"Extrato"}
      openModal={setOpenEntryModal}
      buttonText={"Ver Mais"}
    >
      <Table>
        {movements.length >= 1 &&
          filteredMovements.slice(0, 7).map((option, index) => (
            <tr
              key={index}
              className={`w-full ${index !== 6 && "border-b border-white/60"}`}
            >
              <td className="py-2 px-4 font-medium max-w-0.5 truncate text-white">
                {option.name}
              </td>
              <td
                className={`w-1/4 py-2 px-4 whitespace-nowrap ${
                  option.type === "Entrada"
                    ? "text-green-500"
                    : option.type === "Saída"
                    ? "text-red-500"
                    : "text-yellow-500"
                }`}
              >
                {parseFloat(
                  option.type === "Saída" || option.reservationType === "Saída"
                    ? option.value * -1
                    : option.value
                ).toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </td>
            </tr>
          ))}
      </Table>

      <ModalTable
        modalTitle={"Extrato"}
        openModal={openEntryModal}
        setOpenModal={setOpenEntryModal}
        movements={movements}
        type={"exit"}
        dual={dual && true}
        fetch={fetch}
      />
    </ChartContainer>
  );
}
