export default function TitleHeader() {
  return (
    <div className="flex items-start justify-between gap-4 w-full">
      <div className="flex flex-col">
        <h2 className="text-xl text-easyGreen font-bold">Dashboard</h2>
        <h3 className="text-gray-400 text-xs">Resumo</h3>
      </div>

      <span className="font-bold text-white text-sm truncate">Finanças</span>
    </div>
  );
}
