export default function ComparisonByFinancialType({ movements, uniqueMonths }) {
  const financialTypes = [
    "Alimentação",
    "Lazer",
    "Saúde",
    "Vestuário",
    "Mensalidades",
    "Transporte",
    "Pets",
    "Crédito",
    "Outros",
  ];

  const calculateTotalByTypeAndMonth = (movements, type, month) => {
    return movements
      .reduce((total, movement) => {
        const movementMonth = movement.date.substring(0, 7);
        if (movement.financialType === type && movementMonth === month) {
          total += parseFloat(movement.value);
        }
        return total;
      }, 0)
      .toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
  };

  const calculateTotalByType = (movements, type) => {
    return movements
      .filter((movement) => movement.financialType === type)
      .reduce((total, movement) => total + parseFloat(movement.value), 0)
      .toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
  };
  return (
    <div className="w-full overflow-x-auto">
      <table className="w-full text-center rounded-md overflow-hidden">
        <thead className="text-sm uppercase bg-easyBlueLight text-white">
          <tr>
            <th scope="col" className="px-2 py-3">
              Data
            </th>
            {financialTypes.map((type) => (
              <th
                key={type}
                scope="col"
                className="px-2 py-3 border-l border-white"
              >
                {type}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {uniqueMonths.map((month) => (
            <tr
              key={month}
              className="border-y text-sm text-white/90 bg-easyBlue border-white duration-500 hover:bg-easyBlueLight whitespace-nowrap"
            >
              <th
                scope="row"
                className="px-4 py-2 font-semibold text-white whitespace-nowrap"
              >
                <span className="capitalize">
                  {new Date(`${month}-02`).toLocaleDateString("pt-BR", {
                    month: "short",
                  })}
                </span>

                <span className="ml-1">{new Date(month).getFullYear()}</span>
              </th>
              {financialTypes.map((type) => (
                <td
                  key={`${month}-${type}`}
                  className="px-4 py-2 border-l border-white"
                >
                  {calculateTotalByTypeAndMonth(movements, type, month)}
                </td>
              ))}
            </tr>
          ))}
          <tr className="text-sm text-white font-semibold bg-easyBlueLight duration-500 hover:bg-easyBlueLight/50">
            <th scope="row" className="px-4 py-2  whitespace-nowrap">
              Totais
            </th>
            {financialTypes.map((type) => (
              <td
                key={`total-${type}`}
                className="px-4 py-2 border-l border-white"
              >
                {calculateTotalByType(movements, type)}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
}
