import React from "react";
import { useNavigate } from "react-router-dom";
import { Bell, UserCircle } from "lucide-react";

export default function TopBar() {
  const navigate = useNavigate();

  return (
    <nav className="hidden w-full text-white text-lg justify-between items-center py-4 px-4 gap-4 md:bg-easyBlue md:justify-end md:flex">
      <div className="hidden gap-4 items-center md:flex">
        <button
          className="duration-500 hover:brightness-75 disabled:brightness-50"
          disabled
        >
          <Bell />
        </button>

        <button
          onClick={() => navigate("/profile")}
          className="duration-500 hover:brightness-75"
        >
          <UserCircle />
        </button>
      </div>
    </nav>
  );
}
