export default function ChartContainer({
  title,
  children,
  buttonText,
  openModal,
}) {
  return (
    <div
      className="rounded-2xl border border-white bg-white/5 
        w-full flex-col items-center flex h-[340px]"
    >
      <div className="w-full flex items-center justify-between gap-4 border-b border-white px-4 py-2">
        <span className="font-semibold text-white">{title}</span>

        {buttonText && openModal && (
          <button
            type="button"
            onClick={() => openModal(true)}
            className="font-bold text-white/75 text-xs underline duration-500 hover:brightness-75"
          >
            {buttonText}
          </button>
        )}
      </div>

      {children}
    </div>
  );
}
