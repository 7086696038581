export default function Table({ children }) {
  return (
    <table className="w-full text-sm text-left text-gray-400 overflow-hidden">
      <thead className="text-xs uppercase text-gray-400 ">
        <tr>
          <th scope="col" className="py-2 px-4">
            Descrição
          </th>
          <th scope="col" className="py-2 px-4">
            Valor
          </th>
        </tr>
      </thead>
      <tbody className="w-full">{children}</tbody>
    </table>
  );
}
