import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navigation from "./dashboard/navigation";
import Manutention from "./screenAlerts/manutention";
import TopBar from "./dashboard/topbar";
import { readManutention } from "../utils/appSettings";

export default function PrivateLayout({ children }) {
  const [pageSelected, setPageSelected] = useState(0);
  const navigate = useNavigate();
  const [manutention, setManutention] = useState(null);
  const [manutentionForecast, setManutentionForecast] = useState(null);

  useEffect(() => {
    const unsubscribe = readManutention(setManutention, setManutentionForecast);
    return () => unsubscribe();
  }, [navigate]);

  return (
    <main className="w-full h-full min-h-dvh flex justify-center items-center select-none md:justify-normal md:max-h-dvh md:overflow-x-hidden">
      <Navigation
        componentSelected={pageSelected}
        componentSelection={setPageSelected}
        navigate={navigate}
      />

      {manutention && <Manutention forecast={manutentionForecast} />}

      <div className="w-full flex flex-col min-h-dvh md:max-h-dvh md:overflow-hidden">
        <TopBar
          componentSelected={pageSelected}
          componentSelection={setPageSelected}
        />

        {children}
      </div>
    </main>
  );
}
