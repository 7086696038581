import { ChevronLeft } from "lucide-react";
import { useEffect, useState } from "react";
import ComparisonByFinancialType from "./table/comparisonByFinancialType";
import ComparisonByType from "./table/comparisonByType";

export default function ModalComparison({
  movements,
  comparisonModal,
  setComparisonModal,
}) {
  const getUniqueMonths = (movements) => {
    const uniqueMonths = new Set();
    movements.forEach((movement) => {
      const month = movement.date.substring(0, 7);
      uniqueMonths.add(month);
    });
    return Array.from(uniqueMonths);
  };

  const [uniqueMonths, setUniqueMonths] = useState([]);

  useEffect(() => {
    const months = getUniqueMonths(movements);
    setUniqueMonths(months);
  }, [movements]);

  if (!comparisonModal) return null;

  return (
    <div
      className="w-full h-full fixed top-0 left-0 z-10 overflow-hidden bg-easyBlueLight flex flex-col gap-4 min-h-dvh 
      max-h-dvh p-4 pt-4 pb-20 overflow-x-hidden overflow-y-auto md:left-52 md:top-[55px] md:max-w-[calc(100%-208px)]"
    >
      <div className="w-full flex items-center gap-4 text-white">
        <button
          type="button"
          onClick={() => setComparisonModal(false)}
          className="duration-500 hover:brightness-75"
        >
          <ChevronLeft className="text-easyGreen drop-shadow-[0_2px_5px_#1ce3c7] duration-500 hover:drop-shadow-none" />
        </button>
        <h2 className="text-xl font-bold text-easyGreen">Comparar Meses</h2>
      </div>

      <div className="w-full flex flex-col gap-2">
        <h2 className="text-sm font-bold text-easyYellow">
          Tipo de Movimentação
        </h2>

        <ComparisonByType movements={movements} uniqueMonths={uniqueMonths} />
      </div>

      <div className="w-full flex flex-col gap-2">
        <h2 className="text-sm font-bold text-easyYellow">Tipo de Saídas</h2>

        <ComparisonByFinancialType
          movements={movements}
          uniqueMonths={uniqueMonths}
        />
      </div>
    </div>
  );
}
