import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { readMovement } from "../../utils/movement";
import PrivateLayout from "../privateLayout";
import Loading from "../screenAlerts/loading";
import TitleHeader from "../valueProjections/titleHeader";
import DateHeader from "../valueProjections/dateHeader";
import BalancesAndMovements from "../valueProjections/balanceAndMovements/balancesAndMovements";
import AmountPerDay from "../valueProjections/projections/amountPerDay";
import ExpensesByType from "../valueProjections/balanceAndMovements/components/expensesByType";
import ExpensesByDay from "../valueProjections/balanceAndMovements/components/expensesByDay";
import ExpensesByMonth from "../valueProjections/balanceAndMovements/components/expensesByMonth";
import TableMovements from "../dashboard/table/tableMovements";
import InitialDashboard from "./home/components/initialDashboard";
import ExpenseForecast from "../valueProjections/balanceAndMovements/components/expenseForecast";
import DashboardSkeleton from "../screenAlerts/dashboardSkeleton";

export default function Dashboard({ componentSelection }) {
  const [loading, setLoading] = useState(true);
  const [allMovements, setAllMovements] = useState([]);
  const [monthSelected, setMonthSelected] = useState(null);
  const [comparisonModal, setComparisonModal] = useState(false);

  const extractYearMonth = (dateString) => {
    const [year, month] = dateString.split("-").map(Number);
    return { year, month };
  };

  const filterMovements = () => {
    if (!monthSelected) return [];

    const [selectedMonthPart, selectedYearPart] = monthSelected
      .split("/")
      .map(Number);
    return allMovements.filter(({ date }) => {
      const { year, month } = extractYearMonth(date);
      return year % 100 === selectedYearPart && month === selectedMonthPart;
    });
  };

  const fetchMovement = async () => {
    try {
      const movements = await readMovement();
      setAllMovements(movements);

      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1;
      const currentYear = currentDate.getFullYear().toString().slice(2);

      const formattedDate = `${currentMonth
        .toString()
        .padStart(2, "0")}/${currentYear}`;

      setMonthSelected(formattedDate);
    } catch (error) {
      toast.error("Ocorreu um erro ao buscar movimentações.");
      console.error(error);
    } finally {
      import("react-apexcharts").then(() => {
        setTimeout(() => {
          // Não comprovado que o timeout contribui
          setLoading(false);
        }, 200);
      });
    }
  };

  const filteredMovements = filterMovements();

  const actualReservation = allMovements
    .filter(({ type }) => type === "Reserva")
    .reduce((balance, { reservationType, value }) => {
      const multiplier = reservationType === "Saída" ? -1 : 1;
      return balance + multiplier * parseFloat(value);
    }, 0)
    .toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    .replace("-R$", "R$");

  useEffect(() => {
    fetchMovement();
  }, []);

  return (
    <PrivateLayout>
      <section
        className={`w-full mx-auto h-[calc(100dvh-56px)] overflow-x-hidden px-4 pt-4 pb-8
        md:max-h-[calc(100dvh-56px)] relative ${
          loading && "max-h-screen overflow-hidden"
        }`}
      >
        {loading && <DashboardSkeleton />}

        {!loading && allMovements && !allMovements?.length ? (
          <InitialDashboard
            height={"h-[calc(100dvh-56px)]"}
            selectPage={componentSelection}
          />
        ) : (
          <div className="w-full flex flex-col gap-4 bgEasy">
            <TitleHeader />

            <DateHeader
              comparisonModal={comparisonModal}
              setComparisonModal={setComparisonModal}
              movements={allMovements}
            />

            <BalancesAndMovements
              movements={allMovements}
              isLoading={loading}
            />

            <AmountPerDay movements={allMovements} />

            <div className="w-full gap-4 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
              <ExpensesByType movements={filteredMovements} />

              <ExpenseForecast movements={allMovements} />

              <ExpensesByDay movements={filteredMovements} />

              <ExpensesByMonth movements={allMovements} />

              <TableMovements movements={allMovements} fetch={fetchMovement} />
            </div>
          </div>
        )}
      </section>
    </PrivateLayout>
  );
}
