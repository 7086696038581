import { ChevronDown } from "lucide-react";
import { useEffect, useRef, useState } from "react";

export default function Dropdown({
  title,
  mandatory,
  options,
  onChange,
  selected,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOptionClick = (option) => {
    onChange(option);
    setIsOpen(false);
  };

  return (
    <div
      ref={dropdownRef}
      className="relative w-full max-w-32 min-[380px]:max-w-40"
    >
      <label className="flex items-center whitespace-nowrap mb-2 gap-1 text-xs font-medium text-white">
        <span className="truncate max-w-36">{title}</span>
        {mandatory && <span className="text-red-500">*</span>}
      </label>

      <button
        className="text-easyBlack w-full duration-500 bg-easyGreen hover:brightness-75 focus:outline-none
        rounded-lg text-sm px-4 py-2 text-center font-semibold flex items-center justify-between gap-2"
        type="button"
        onClick={toggleDropdown}
      >
        <span className="truncate max-w-full">{selected}</span> <ChevronDown />
      </button>

      {isOpen && (
        <div className="z-40 bg-white divide-y divide-gray-100 rounded-lg shadow w-[120px] max-h-52 absolute overflow-x-hidden">
          <ul className="text-sm text-gray-700 w-full">
            {options.map((option, index) => (
              <li key={index} className="w-full">
                <button
                  className="w-full text-left text-sm font-semibold block p-2 hover:bg-gray-200"
                  onClick={() => handleOptionClick(option)}
                >
                  {option}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
