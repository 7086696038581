export default function SimpleButton({
  id,
  title,
  functionToExecute,
  customStyle,
  disabled,
  isSubmit,
  children,
}) {
  return (
    <button
      id={id}
      type={isSubmit ? "submit" : "button"}
      disabled={disabled ? true : false}
      onClick={functionToExecute}
      className={`font-bold text-white bg-easyBlue px-4 py-1 rounded-xl flex gap-2 items-center justify-center 
      text-xs duration-500 hover:brightness-75 disabled:brightness-50 disabled:cursor-not-allowed ${customStyle}`}
    >
      {children} {title}
    </button>
  );
}
