import React from "react";
import ApexChart from "../../../ApexChart";
import ChartContainer from "./chartContainer";

export default function ExpensesByType({ movements }) {
  const labels = [
    "Alimentação",
    "Lazer",
    "Saúde",
    "Vestuário",
    "Mensalidades",
    "Transporte",
    "Pets",
    "Crédito",
    "Outros",
  ];

  function getExpensesCounts(allMovements) {
    const counts = labels.map((financialType) => {
      const filteredTotal = allMovements.flat().reduce((total, movement) => {
        if (
          movement?.type === "Saída" &&
          movement.financialType === financialType
        ) {
          return total + parseFloat(movement.value);
        }
        return total;
      }, 0);

      const total = allMovements.flat().reduce((total, movement) => {
        if (movement?.type === "Saída") {
          return total + parseFloat(movement.value);
        }
        return total;
      }, 0);

      const percentage = total > 0 ? (filteredTotal / total) * 100 : 0;
      return parseFloat(percentage.toFixed(2));
    });

    return counts;
  }

  const series = getExpensesCounts(movements);

  return (
    <ChartContainer title={"Despesas por Categoria"}>
      <div className="w-full">
        <ApexChart
          labels={[
            "Alimentação",
            "Lazer",
            "Saúde",
            "Vestuário",
            "Mensalidades",
            "Transporte",
            "Pets",
            "Crédito",
            "Outros",
          ]}
          series={series}
          type="pie"
          colors={[
            "#ffbd3d",
            "#00cf44",
            "#993399",
            "#ec0000",
            "#0A6847",
            "#FC4100",
            "#5a9cff",
            "#2D26B2",
            "#FF5BAE",
          ]}
          height={"290px"}
          hasLegend
        />
      </div>
    </ChartContainer>
  );
}
