import Balance from "./components/balance";
import Evolution from "./components/evolution";
import Reservation from "./components/reservation";
import SideContainer from "./components/sideContainer";

export default function BalancesAndMovements({ movements, isLoading }) {
  return (
    <div className="w-full flex flex-col gap-4 sm:flex-row sm:justify-between">
      <SideContainer>
        <Balance movements={movements} isLoading={isLoading} />
      </SideContainer>

      <SideContainer>
        <Reservation movements={movements} isLoading={isLoading} />
      </SideContainer>

      <SideContainer>
        <Evolution movements={movements} isLoading={isLoading} />
      </SideContainer>
    </div>
  );
}
