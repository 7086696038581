export default function DashboardSkeleton({ height }) {
  return (
    <div className="w-full flex bgEasy absolute z-[50] top-0 left-0 min-h-screen">
      <div className="w-full flex flex-col gap-4 animate-pulse">
        <div className="flex items-start justify-between gap-4 w-full">
          <div className="flex flex-col">
            <div className="w-28 h-7 bg-easyGreen rounded-md "></div>
            <div className="w-24 h-5 mt-1 bg-gray-400 rounded-md"></div>
          </div>

          <div className="w-20 h-5 bg-white rounded-md"></div>
        </div>

        <div className="flex items-end justify-between gap-4 w-full">
          <div className="flex flex-col justify-end whitespace-nowrap gap-2 text-xs font-medium text-white">
            <div className="w-10 h-5 bg-white rounded-md"></div>

            <span className="w-28 h-8 bg-easyGreen rounded-md"></span>
          </div>

          <div className="w-28 h-5 bg-white rounded-md"></div>
        </div>

        <div className="w-full flex flex-col gap-4 sm:flex-row sm:justify-between">
          <div className="w-full rounded-2xl border border-white bg-easyBlue pt-2 pb-1 h-32"></div>

          <div className="w-full rounded-2xl border border-white bg-easyBlue pt-2 pb-1 h-32"></div>

          <div className="w-full rounded-2xl border border-white bg-easyBlue pt-2 pb-1 h-32"></div>
        </div>

        <div className="w-full rounded-2xl border border-white flex min-h-20 bg-white/5"></div>

        <div className="w-full gap-4 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
          <div className="rounded-2xl border border-white bg-white/5 w-full h-[340px]"></div>

          <div className="rounded-2xl border border-white bg-white/5 w-full h-[340px]"></div>

          <div className="rounded-2xl border border-white bg-white/5 w-full h-[340px]"></div>

          <div className="rounded-2xl border border-white bg-white/5 w-full h-[340px]"></div>

          <div className="rounded-2xl border border-white bg-white/5 w-full h-[340px]"></div>

          <div className="rounded-2xl border border-white bg-white/5 w-full h-[340px]"></div>
        </div>
      </div>
    </div>
  );
}
