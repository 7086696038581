import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { toast } from "react-hot-toast";
import { useAuth } from "./contexts/authContext";
import Loading from "../components/screenAlerts/loading";

const PrivateRoute = ({ element: Component, ...rest }) => {
  const { currentUser } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (currentUser === undefined) {
      setLoading(true);
    } else if (!currentUser) {
      toast.error("Você precisa estar logado para acessar esta página.");
      setTimeout(() => {
        navigate("/", { state: { from: location } });
        setLoading(false);
      }, 1000);
    } else {
      setLoading(false);
    }
  }, [currentUser, navigate, location]);

  if (loading) {
    return <Loading height={"h-dvh"} />;
  }

  return currentUser ? <Component {...rest} /> : null;
};

export default PrivateRoute;
