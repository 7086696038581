import { auth } from "../services/firebase";
import {
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  createUserWithEmailAndPassword,
  updateProfile,
  sendPasswordResetEmail
} from "firebase/auth";
import toast from 'react-hot-toast';

const handleAuthError = (error) => {
  switch (error.code) {
    case "auth/popup-closed-by-user":
      toast.error("A janela pop-up foi fechada. Tente novamente.");
      break;
    case "auth/invalid-email":
      toast.error("Email inválido. Verifique e tente novamente.");
      break;
    case "auth/operation-not-allowed":
      toast.error("Erro durante o login. Operação não permitida.");
      break;
    case "auth/invalid-credential":
      toast.error("Email e/ou senha inválidos. Verifique e tente novamente.");
      break;
    case "auth/user-disabled":
      toast.error("Sua conta foi desativada. Entre em contato com o suporte.");
      break;
    case "auth/user-not-found":
      toast.error("Usuário não encontrado. Verifique e tente novamente.");
      break;
    case "auth/wrong-password":
      toast.error("Senha incorreta. Verifique e tente novamente.");
      break;
    case "auth/too-many-requests":
      toast.error("Muitas tentativas de login. Tente novamente mais tarde.");
      break;
    case "auth/weak-password":
      toast.error("A senha deve ter pelo menos 6 caracteres.");
      break;
    default:
      toast.error("Ocorreu um erro. Tente novamente.");
      break;
  }
};

const handleLogin = async (userEmail, userPassword, navigate) => {
  try {
    if (userEmail === "" || userPassword === "") {
      toast.error("Por favor, preencha o email e a senha.");
    } else {
      await signInWithEmailAndPassword(auth, userEmail, userPassword);
      toast.success('Login realizado com sucesso!');
      navigate("/dashboard");
    }
  } catch (error) {
    await signOut(auth);
    handleAuthError(error);
  }
};

const handleLoginGoogle = async (navigate) => {
  const provider = new GoogleAuthProvider();

  try {
    await signInWithPopup(auth, provider);
    toast.success('Login realizado com sucesso!');
    navigate("/dashboard");
  } catch (error) {
    await signOut(auth);
    handleAuthError(error);
  }
};

const handleLogout = async (navigate) => {
  try {
    await signOut(auth);
    toast.success('Logout realizado com sucesso!');
    navigate("/");
  } catch (error) {
    toast.error("Ocorreu um erro. Tente novamente.");
  }
};

const handleRegister = async (formData, navigate) => {
  try {
    if (!formData.name || !formData.email || !formData.password || !formData.confirmPassword) {
      toast.error('Por favor, preencha todos os campos.');
      return
    }

    if (formData.password !== formData.confirmPassword) {
      toast.error("As senhas não coincidem.");
      return
    }

    await createUserWithEmailAndPassword(auth, formData.email, formData.password);
    await updateProfile(auth.currentUser, { displayName: formData.name });

    toast.success('Conta criada com sucesso!');
    navigate("/dashboard");
  } catch (error) {
    handleAuthError(error);
  }
};

const handleRecoverPassword = async (email) => {
  try {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        toast.success('Email enviado com sucesso!');
      })
      .catch(() => {
        toast.error('Email inválido.');
      });
  } catch (error) {
    toast.error('Email inválido.');
  }
};

export { handleLogin, handleLoginGoogle, handleLogout, handleRegister, handleRecoverPassword };
