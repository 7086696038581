import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import {
  browserLocalPersistence,
  getAuth,
  setPersistence,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCq4VsOk1lgo2hXVhlK1DcsCZU1jhmTZwE",
  authDomain: "esasyfinancas.firebaseapp.com",
  projectId: "esasyfinancas",
  storageBucket: "esasyfinancas.appspot.com",
  messagingSenderId: "127764982523",
  appId: "1:127764982523:web:f5d65452ff6298a88fde5e",
  measurementId: "G-K757C90T8Q"
};

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const auth = getAuth(app);

setPersistence(auth, browserLocalPersistence);

export { db, auth };
