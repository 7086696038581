import { LogOut, Pencil, UserRound } from "lucide-react";
import PrivateLayout from "../../privateLayout";
import { auth } from "../../../services/firebase";
import { handleLogout } from "../../../utils/loginAndRegister";
import SimpleButton from "../../buttons/simpleButton";
import ProfileInfo from "./components/profileInfo";
import Toggle from "../../buttons/toggle";
import Tooltip from "../../tooltip";
import { useState } from "react";

export default function Profile({ navigate }) {
  const [imageError, setImageError] = useState(false);

  return (
    <PrivateLayout>
      <section
        className="w-full mx-auto h-[calc(100dvh-56px)] overflow-x-hidden bgEasy relative 
        flex px-4 flex-col gap-4 pt-4 pb-20 md:max-h-[calc(100dvh-56px)] md:pb-4"
      >
        <div className="flex flex-col">
          <h2 className="text-xl text-easyGreen font-bold">Perfil</h2>
          <h3 className="text-gray-400 text-xs">Edite seu perfil</h3>
        </div>

        <div className="w-full flex flex-col gap-4 p-6 rounded-2xl border border-[#5e5f72] bg-[#25273f] md:max-w-max">
          <div className="flex flex-col items-center gap-4 md:flex-row">
            <div
              className="w-24 h-24 flex items-center justify-center 
            bg-[#C4CCDF] text-easyBlue rounded-2xl aspect-square"
            >
              {auth?.currentUser?.photoURL && !imageError ? (
                <img
                  src={auth?.currentUser?.photoURL}
                  alt="Foto de perfil"
                  className="w-full h-full object-cover rounded-2xl"
                  onError={() => setImageError(true)}
                />
              ) : (
                <UserRound size={48} />
              )}
            </div>

            <div className="grid gap-x-4 gap-y-2 grid-cols-2">
              <ProfileInfo
                label="Nome"
                value={auth?.currentUser?.displayName}
              />

              <ProfileInfo
                label="Endereço de Email"
                value={auth?.currentUser?.email}
              />

              <ProfileInfo
                label="Criação da Conta"
                value={new Date(
                  auth?.currentUser?.metadata?.creationTime
                ).toLocaleDateString()}
              />
            </div>
          </div>

          {/* <div className="flex items-center gap-1">
            <Toggle label={"Ocultar Valores"} />{" "}
            <Tooltip message={"Não oculta valores em gráficos"} />
          </div> */}

          <div className="flex flex-col items-center gap-4 md:flex-row">
            <SimpleButton id={"edit"} title={"Editar"} disabled>
              <Pencil className="w-4 h-4" />
            </SimpleButton>

            <SimpleButton
              id={"logout"}
              title={"Sair"}
              customStyle={"!text-red-500"}
              functionToExecute={() => handleLogout(navigate)}
            >
              <LogOut className="w-4 h-4" />
            </SimpleButton>
          </div>
        </div>

        <div className="w-[150px] sm:w-full sm:max-w-[250px] ml-auto bottom-4 right-4 md:absolute">
          <img
            src="/illustrations/home.gif"
            alt="Ilustração foguete decolando do computador"
            className="w-full h-full object-cover"
          />
        </div>
      </section>
    </PrivateLayout>
  );
}
