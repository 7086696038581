import { ref, set, onValue, remove, update } from "firebase/database";
import { auth, db } from "../services/firebase";
import toast from 'react-hot-toast';

const addMovement = async (type, formData) => {
  const uid = auth.currentUser.uid;
  const sendHour = new Date().getTime();
  try {
    set(ref(db, `users/${uid}/financial/movements/${type}/${sendHour}`), {
      type: formData.type,
      name: formData.name,
      date: formData.date,
      value: formData.value,
      ...(formData.type === "Saída" && { financialType: formData.financialType }),
      ...(formData.type === "Reserva" && { reservationType: formData.reservationType }),
    });

    toast.success("Movimentação adicionada!");
  } catch (error) {
    toast.error("Ocorreu um erro ao adicionar movimentação. Tente novamente.");
  }
}

const deleteMovement = async (type, movementId) => {
  if (!type || !movementId) {
    toast.error("Dados de movimentação inválidos.");
    return
  }

  const uid = auth.currentUser.uid;
  try {
    const itemRef = ref(db, `users/${uid}/financial/movements/${type}/${movementId}`);
    await remove(itemRef);
    toast.success("Movimentação removida!");
  } catch (error) {
    toast.error("Ocorreu um erro ao excluir a movimentação.");
  }
};

const updateMovement = async (oldData, movementId, formData) => {
  const isEqual = (a, b) =>
    a.name === b.name &&
    a.date === b.date &&
    a.value === b.value &&
    a.type === b.type &&
    (a.type !== "Saída" || a.financialType === b.financialType) &&
    (a.type !== "Reserva" || a.reservationType === b.reservationType);

  if (isEqual(oldData, formData))
    return toast.error("Não há alterações.");

  if (!oldData || !movementId || !formData)
    return toast.error("Dados de movimentação inválidos.");

  const uid = auth.currentUser.uid;
  const mapType = (type) => ({ Entrada: "entry", Saída: "exit" }[type] || "reservation");

  const oldRef = ref(db, `users/${uid}/financial/movements/${mapType(oldData.type)}/${movementId}`);
  const newRef = ref(db, `users/${uid}/financial/movements/${mapType(formData.type)}/${movementId}`);

  const data = {
    type: formData.type,
    name: formData.name,
    date: formData.date,
    value: formData.value,
    ...(formData.type === "Saída" && { financialType: formData.financialType }),
    ...(formData.type === "Reserva" && { reservationType: formData.reservationType }),
  };

  try {
    if (oldData.type !== formData.type) {
      await remove(oldRef);
      await set(newRef, data);
    } else {
      await update(newRef, data);
    }

    toast.success("Movimentação atualizada com sucesso!");
  } catch {
    toast.error("Ocorreu um erro ao atualizar a movimentação.");
  }
};


const readMovement = async () => {
  const uid = auth.currentUser.uid;
  const userDbRef = ref(db, `users/${uid}/financial/movements`);

  return new Promise((resolve, reject) => {
    onValue(userDbRef, (snapshot) => {
      const data = snapshot.val();

      if (data) {
        const combinedArray = [];
        const addTimeStamp = (arr) => {
          return arr ? Object.keys(arr).map(key => {
            const obj = arr[key];
            return { ...obj, timeStamp: parseInt(key) };
          }) : [];
        };
        combinedArray.push(...addTimeStamp(data.entry || {}));
        combinedArray.push(...addTimeStamp(data.exit || {}));
        combinedArray.push(...addTimeStamp(data.reservation || {}));

        resolve(combinedArray);
      } else {
        resolve([]);
      }
    }, (error) => {
      console.error(error);
      reject(error);
    });
  });
};

// const readPartnerUid = async () => {
//   const uid = auth.currentUser.uid;
//   const userPartnerUidRef = ref(db, `users/${uid}/friendUid`);
//   try {
//     return new Promise((resolve, reject) => {
//       onValue(userPartnerUidRef, (snapshot) => {
//         const data = snapshot.val();
//         resolve(data);
//       }, (error) => {
//         reject(error);
//       });
//     });
//   } catch (error) {
//     toast.error("Ocorreu um erro ao buscar o UID do amigo.");
//     throw error;
//   }
// }

// const readPartnerMovement = async () => {
//   try {
//     const partnerData = await readPartnerUid();
//     const { name, uid } = partnerData;
//     const userPartnerUidRef = ref(db, `users/${uid}`);

//     return new Promise((resolve, reject) => {
//       onValue(userPartnerUidRef, (snapshot) => {
//         const movements = snapshot.val();
//         resolve({ name, movements });
//       }, (error) => {
//         reject(error);
//       });
//     });
//   } catch (error) {
//     if (error instanceof TypeError && error.message.includes("Cannot destructure property 'name' of 'partnerData' as it is null")) {
//       toast.error("Parceiro Não Encontrado.");
//     } else {
//       toast.error("Ocorreu um erro ao ler movimentações do parceiro.");
//     }
//   }

// }

export { addMovement, readMovement, deleteMovement, updateMovement };