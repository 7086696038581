export default function CustomButton({
  id,
  title,
  functionToExecute,
  disabled,
  isSubmit,
}) {
  return (
    <button
      id={id}
      type={isSubmit ? "submit" : "button"}
      disabled={disabled ? true : false}
      onClick={functionToExecute}
      className="bg-easyGreen py-2 px-4 rounded-md text-center text-easyBlue w-full md:max-w-40
      duration-500 hover:brightness-75 disabled:brightness-50"
    >
      {title}
    </button>
  );
}
