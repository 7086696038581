import { Info } from "lucide-react";

export default function Tooltip({ message }) {
  return (
    <div className="group cursor-pointer relative inline-block text-center">
      <Info size={16} className="text-white duration-500 hover:brightness-75" />
      <div
        className="opacity-0 bg-easyGreen text-easyBlack text-center font-semibold 
        text-xs rounded-lg py-1.5 absolute duration-300 z-10 group-hover:opacity-100 -bottom-1 
        ml-5 px-2 pointer-events-none w-min min-[400px]:w-max"
      >
        {message}
      </div>
    </div>
  );
}
