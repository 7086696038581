export default function Loading({ height }) {
  return (
    <div
      className={`w-full absolute z-[50] bg-[#1c1d33] top-0 left-0 flex flex-col 
      gap-2 items-center justify-center ${height}`}
    >
      <div className="w-[150px]">
        <img
          src="/illustrations/loading.gif"
          alt="Ilustração gráficos"
          className="w-full h-full object-cover"
        />
      </div>

      <div className="text-lg text-white flex items-center gap-0.5 animate-pulse">
        <span>Carregando</span>
        <div className="flex space-x-0.5 justify-center items-center mt-2">
          <div className="h-1 w-1 bg-white rounded-full animate-bounce [animation-delay:-0.3s]"></div>
          <div className="h-1 w-1 bg-white rounded-full animate-bounce [animation-delay:-0.15s]"></div>
          <div className="h-1 w-1 bg-white rounded-full animate-bounce"></div>
        </div>
      </div>
    </div>
  );
}
