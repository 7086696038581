import { useEffect, useState } from "react";
import FormController from "./components/login/formController";
import Loading from "./components/screenAlerts/loading";
import { useAuth } from "./utils/contexts/authContext";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser) {
      navigate("/home");
    } else {
      setLoading(false);
    }
  }, [currentUser, navigate]);

  return (
    <main className="w-full h-full flex justify-center items-center select-none min-h-dvh py-8 relative">
      {loading && <Loading height={"h-dvh"} />}

      <div className="w-full max-w-[90%] mx-auto flex flex-col gap-4 items-center justify-center sm:justify-around sm:flex-row md:max-w-[1200px]">
        <div className="flex flex-col items-center gap-4 text-center">
          <div className="w-[150px] sm:w-full sm:max-w-[300px]">
            <img
              src="/illustrations/graph.gif"
              alt="Ilustração gráficos"
              className="w-full h-full object-cover"
            />
          </div>

          <div className="w-14 absolute left-4 top-4 md:w-28">
            <img
              src="/logo/noBgNoText.png"
              alt="Easy Finanças"
              className="w-full h-full object-cover"
            />
          </div>

          <h1 className="font-bold text-xl text-easyGreen">Easy Finanças</h1>

          <p className="text-white">
            Veja onde está o seu <br className="sm:hidden" /> dinheiro de forma
            fácil!
          </p>
        </div>

        <FormController />
      </div>
    </main>
  );
}
