import ModalComparison from "../dashboard/modalComparison";

export default function DateHeader({
  movements,
  comparisonModal,
  setComparisonModal,
}) {
  return (
    <div className="flex items-end justify-between gap-4 w-full">
      <div className="flex flex-col justify-end whitespace-nowrap gap-2 text-xs font-medium text-white">
        <span className="truncate max-w-32 sm:max-w-none">Data</span>

        <span
          className="text-easyBlack duration-500 bg-easyGreen rounded-lg text-sm 
          px-4 py-2 text-center font-semibold"
        >
          {(() => {
            const currentDate = new Date();
            const day = currentDate.getDate().toString().padStart(2, "0");
            const month = (currentDate.getMonth() + 1)
              .toString()
              .padStart(2, "0");
            const year = currentDate.getFullYear().toString().slice(2);

            return `${day}/${month}/${year}`;
          })()}
        </span>
      </div>

      <button
        onClick={() => setComparisonModal(true)}
        className="text-gray-400 text-xs underline duration-500 hover:brightness-75"
      >
        Comparar Meses
      </button>

      <ModalComparison
        movements={movements}
        comparisonModal={comparisonModal}
        setComparisonModal={setComparisonModal}
      />
    </div>
  );
}
