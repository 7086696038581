import { useState } from "react";
import { deleteMovement, updateMovement } from "../../utils/movement";
import {
  Apple,
  Armchair,
  CalendarDaysIcon,
  CarFrontIcon,
  ChevronLeft,
  Clock,
  CreditCard,
  HandCoinsIcon,
  PawPrintIcon,
  Pen,
  PiggyBank,
  ShirtIcon,
  StethoscopeIcon,
  Trash2,
  TrendingDown,
  TrendingUp,
  User,
} from "lucide-react";
import Dropdown from "../dropdown";
import MovementInput from "../movement/movementInput";
import ConfirmModal from "../modals/confirmModal";
import Pagination from "../pagination";
import Modal from "../modals/modal";
import { calculateBalances } from "../../utils/calculations/currentBalances";
import toast from "react-hot-toast";
import CustomButton from "../buttons/customButton";

export default function ModalTable({
  openModal,
  setOpenModal,
  modalTitle,
  movements,
  dual,
  fetch,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    type: "Todos",
    category: "Todas",
    order: "Tempo",
    searchTerm: "",
    date: "Qualquer",
  });
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [editingMovement, setEditingMovement] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    date: "",
    value: "",
    type: "",
    financialType: "",
    reservationType: "",
  });

  const handleOpenEdit = async (option) => {
    setFormData({
      name: option?.name,
      date: option?.date,
      value: option?.value,
      type: option?.type,
      financialType: option?.financialType,
      reservationType: option?.reservationType,
    });
    setOpenModalEdit(true);
    setEditingMovement(option?.timeStamp);
  };

  const handleDropdownChange = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };

  const handleValueChange = (value) => {
    if (/^\d*[.,]?\d*$/.test(value)) {
      const formattedValue = value.replace(",", ".");
      setFormData({
        ...formData,
        value: formattedValue,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await handleUpdateMovement(formData);
  };

  const handleUpdateMovement = async (formData) => {
    setIsSubmitting(true);
    const oldMovement = movements.find(
      (movement) => movement.timeStamp === editingMovement
    );
    const showError = (message) => toast.error(message);

    // Validações essenciais
    if (!formData.name || !formData.date || !formData.value || !formData.type) {
      showError("Por favor, preencha todos os campos.");
      setIsSubmitting(false);
      return;
    }

    if (formData.value === "0") {
      showError("Por favor, preencha o valor.");
      setIsSubmitting(false);
      return;
    }

    if (formData.type === "Saída" && !formData.financialType) {
      showError("Por favor, preencha o tipo de gasto.");
      setIsSubmitting(false);
      return;
    }

    if (formData.type === "Reserva" && !formData.reservationType) {
      showError("Por favor, preencha o tipo de reserva.");
      setIsSubmitting(false);
      return;
    }

    const today = new Date().setHours(0, 0, 0, 0);
    const movementDate = new Date(`${formData.date}T00:00:00`);

    if (movementDate > today) {
      showError("A data não pode estar no futuro.");
      setIsSubmitting(false);
      return;
    }

    const balanceKey =
      formData.type === "Saída" ||
      (formData.type === "Reserva" && formData.reservationType === "Entrada")
        ? "currentBalance"
        : "currentReservation";

    const balances = await calculateBalances();
    const actualBalance = balances?.[balanceKey] || 0;

    if (
      formData.type !== "Entrada" &&
      formData.value !== oldMovement.value &&
      actualBalance - parseFloat(formData.value) < 0
    ) {
      showError("Você não tem saldo suficiente.");
      setIsSubmitting(false);
      return;
    }

    await updateMovement(oldMovement, editingMovement, formData);
    setIsSubmitting(false);
    setEditingMovement(null);
    setOpenModalEdit(false);
    await fetch();
  };

  const dateOptions = ["Qualquer", "Mês Atual", "Mês Anterior"];
  const typeOptions = ["Todos", "Entradas", "Reservas", "Saídas"];
  const categoryOptions = [
    "Todas",
    "Alimentação",
    "Lazer",
    "Saúde",
    "Vestuário",
    "Mensalidades",
    "Transporte",
    "Pets",
    "Crédito",
    "Outros",
  ];
  const orderOptions = ["Tempo", "Decrescente", "Crescente"];

  const normalizeString = (str) => {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  };

  const currentDate = new Date();
  const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const currentYear = currentDate.getFullYear().toString();
  const currentYearMonth = `${currentYear}-${currentMonth}`;

  // Calcula o mês anterior
  const previousMonthDate = new Date(
    currentDate.setMonth(currentDate.getMonth() - 1)
  );
  const previousMonth = (previousMonthDate.getMonth() + 1)
    .toString()
    .padStart(2, "0");
  const previousYear = previousMonthDate.getFullYear().toString();
  const previousYearMonth = `${previousYear}-${previousMonth}`;

  const filteredOptions =
    movements.length > 0
      ? movements.filter((option) => {
          const matchesType =
            filters.type === "Todos"
              ? true
              : filters.type === "Entradas"
              ? option?.type === "Entrada"
              : filters.type === "Reservas"
              ? option?.type === "Reserva"
              : option?.type === "Saída";
          const matchesCategory =
            filters.category === "Todas" ||
            option?.financialType === filters.category;
          const matchesSearch = normalizeString(option?.name).includes(
            normalizeString(filters.searchTerm)
          );
          const matchesMonth =
            filters.date === "Qualquer"
              ? true
              : filters.date === "Mês Atual"
              ? option?.date.startsWith(currentYearMonth)
              : filters.date === "Mês Anterior"
              ? option?.date.startsWith(previousYearMonth)
              : false;
          return (
            matchesType && matchesCategory && matchesSearch && matchesMonth
          );
        })
      : [];

  if (filters.order === "Decrescente") {
    filteredOptions.sort((a, b) => parseFloat(b.value) - parseFloat(a.value));
  } else if (filters.order === "Crescente") {
    filteredOptions.sort((a, b) => parseFloat(a.value) - parseFloat(b.value));
  }

  const handleFilterChange = (field, value) => {
    setCurrentPage(1);
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  const handleDeleteMovement = async (type, movementId) => {
    await deleteMovement(type, movementId);
    await fetch();
  };

  function calcBalance() {
    let totalBalance = 0;
    let uniqueType = null;

    if (movements && Array.isArray(movements) && filteredOptions.length > 0) {
      let hasEntry = false;
      let hasExit = false;
      let hasReservation = false;

      filteredOptions.flat().forEach((movement) => {
        if (movement.type === "Entrada") {
          hasEntry = true;
        } else if (movement.type === "Saída") {
          hasExit = true;
        } else if (movement.type === "Reserva") {
          hasReservation = true;
        }
      });

      if (hasEntry + hasExit + hasReservation === 1) {
        if (hasEntry) {
          uniqueType = "Entrada";
        } else if (hasExit) {
          uniqueType = "Saída";
        } else if (hasReservation) {
          uniqueType = "Reserva";
        }
      }

      filteredOptions.flat().forEach((movement) => {
        if (hasEntry && hasExit && hasReservation) {
          totalBalance +=
            parseFloat(movement.value) *
            (movement.type === "Entrada" ||
            (movement.type === "Reserva" &&
              movement.reservationType === "Saída")
              ? 1
              : -1);
        } else if (hasEntry || hasExit || hasReservation) {
          if (uniqueType && movement.type === uniqueType) {
            totalBalance +=
              parseFloat(movement.value) *
              (movement.reservationType === "Entrada"
                ? 1
                : movement.reservationType === "Saída"
                ? -1
                : 1);
          }
        }
      });
    }

    return {
      uniqueType: uniqueType,
      totalBalance: totalBalance.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      }),
    };
  }

  // Paginação
  const itemsPerPage = 9;
  const totalPages = Math.ceil(filteredOptions.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = filteredOptions.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  const goToNextPage = () =>
    setCurrentPage((prev) => (prev === totalPages ? 1 : prev + 1));

  const goToPreviousPage = () =>
    setCurrentPage((prev) => (prev === 1 ? totalPages : prev - 1));

  const FinancialTypeIcon = ({ financialType }) => {
    const iconMap = {
      Alimentação: <Apple size={18} />,
      Lazer: <Armchair size={18} />,
      Saúde: <StethoscopeIcon size={18} />,
      Vestuário: <ShirtIcon size={18} />,
      Mensalidades: <CalendarDaysIcon size={18} />,
      Transporte: <CarFrontIcon size={18} />,
      Pets: <PawPrintIcon size={18} />,
      Crédito: <CreditCard size={18} />,
      Outros: <HandCoinsIcon size={18} />,
    };

    if (financialType in iconMap) {
      return iconMap[financialType];
    } else {
      return null;
    }
  };

  return (
    openModal && (
      <div
        className="w-full h-full fixed top-0 left-0 overflow-hidden bg-easyBlueLight flex flex-col gap-4 min-h-dvh 
        max-h-dvh p-4 pb-20 overflow-x-hidden overflow-y-auto md:left-52 md:top-[55px] md:max-w-[calc(100%-208px)]"
      >
        <div className="w-full flex items-center gap-4 text-white">
          <button
            type="button"
            onClick={() => {
              setOpenModal(false);
              setCurrentPage(1);
              setFilters({
                type: "Todos",
                category: "Todas",
                order: "Tempo",
                searchTerm: "",
                date: "Qualquer",
              });
            }}
            className="duration-500 hover:brightness-75"
          >
            <ChevronLeft className="text-easyGreen drop-shadow-[0_2px_5px_#1ce3c7] duration-500 hover:drop-shadow-none" />
          </button>

          <h2 className="text-xl font-bold text-easyGreen">{modalTitle}</h2>

          <div className="ml-auto flex flex-col gap-2">
            <div className="text-white font-bold text-xs flex items-center gap-1 ml-auto sm:text-base">
              <span className="sm:text-sm">
                <span className="sm:text-sm">
                  {calcBalance().uniqueType &&
                  calcBalance().uniqueType !== "Reserva"
                    ? "Total:"
                    : "Saldo:"}
                </span>
              </span>
              <span
                className={`${
                  calcBalance().uniqueType === "Entrada"
                    ? "text-green-500"
                    : calcBalance().uniqueType === "Saída"
                    ? "text-red-500"
                    : calcBalance().uniqueType === "Reserva"
                    ? "text-yellow-500"
                    : "text-green-500"
                }`}
              >
                {calcBalance().totalBalance}
              </span>
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col gap-4 md:flex-row md:items-end">
          <div className="flex flex-col gap-2 md:order-2 lg:flex-row">
            <div className="flex justify-between gap-4">
              <Dropdown
                title={"Data:"}
                selected={filters.date}
                options={dateOptions}
                onChange={(value) => handleFilterChange("date", value)}
              />

              <Dropdown
                title={"Tipo:"}
                selected={filters.type}
                options={typeOptions}
                onChange={(value) => handleFilterChange("type", value)}
              />
            </div>
            <div className="flex justify-between gap-4">
              <Dropdown
                title={"Categoria:"}
                selected={filters.category}
                options={categoryOptions}
                onChange={(value) => handleFilterChange("category", value)}
              />

              <Dropdown
                title={"Ordem:"}
                selected={filters.order}
                options={orderOptions}
                onChange={(value) => handleFilterChange("order", value)}
              />
            </div>
          </div>
          <MovementInput
            id="movementName"
            title="Filtrar por nome"
            placeholder={"Ex: Hamburger"}
            value={filters.searchTerm}
            setValue={(value) => handleFilterChange("searchTerm", value)}
          />
        </div>

        <div
          className={`w-full gap-4 grid-cols-1 sm:grid-cols-2 md:pb-0 lg:grid-cols-3 2xl:min-h-[calc(100dvh-266px)] ${
            currentItems.length >= 1 ? "grid" : "flex"
          }`}
        >
          {currentItems.length > 0 ? (
            currentItems.map((option, index) => (
              <div
                key={index}
                className="w-full p-4 rounded-2xl border border-[#5b5fc0] bg-[#11122d] flex flex-col justify-between overflow-hidden"
              >
                <div className="flex items-start justify-between gap-1 w-full">
                  <div className="flex flex-col gap-1 w-full max-w-[90%]">
                    <span className="font-bold text-white text-xs truncate max-w-full sm:text-sm 2xl:text-base">
                      {option?.name}
                    </span>

                    <div className="text-gray-400 text-[10px] flex items-center gap-4 2xl:text-xs">
                      <div className="flex items-center gap-1">
                        <Clock size={14} />
                        <span>
                          {option?.date.split("-").reverse().join("/")}{" "}
                        </span>
                      </div>

                      {dual && (
                        <div className="flex items-center gap-1">
                          <User size={16} />
                          <span>{option?.user}</span>
                        </div>
                      )}
                    </div>
                  </div>

                  <Modal
                    title="Editar Movimentação"
                    isOpen={openModalEdit}
                    setIsOpen={setOpenModalEdit}
                    toggleButton={
                      <button
                        type="button"
                        onClick={() => handleOpenEdit(option)}
                        className="duration-500 hover:brightness-75 text-yellow-500"
                      >
                        <Pen size={18} />
                      </button>
                    }
                  >
                    <form
                      onSubmit={handleSubmit}
                      className="w-full flex flex-col gap-4"
                    >
                      <div className="flex items-center justify-between gap-4 md:justify-start">
                        <Dropdown
                          title="Tipo"
                          selected={formData.type}
                          name="type"
                          options={["Entrada", "Saída", "Reserva"]}
                          onChange={(value) =>
                            handleDropdownChange("type", value)
                          }
                        />
                        {formData.type === "Saída" && (
                          <Dropdown
                            title="Categoria"
                            selected={formData.financialType}
                            name="financialType"
                            options={[
                              "Alimentação",
                              "Lazer",
                              "Saúde",
                              "Vestuário",
                              "Mensalidades",
                              "Transporte",
                              "Pets",
                              "Crédito",
                              "Outros",
                            ]}
                            onChange={(value) =>
                              handleDropdownChange("financialType", value)
                            }
                          />
                        )}
                        {formData.type === "Reserva" && (
                          <Dropdown
                            title="Tipo de Reserva"
                            selected={formData.reservationType}
                            name="reservationType"
                            options={["Entrada", "Saída"]}
                            onChange={(value) =>
                              handleDropdownChange("reservationType", value)
                            }
                          />
                        )}
                      </div>

                      <MovementInput
                        id="name"
                        title="Nome"
                        name="name"
                        value={formData.name}
                        setValue={(value) =>
                          setFormData({ ...formData, name: value })
                        }
                      />
                      <MovementInput
                        id="date"
                        title="Data"
                        type="date"
                        name="date"
                        date
                        value={formData.date}
                        setValue={(value) =>
                          setFormData({ ...formData, date: value })
                        }
                      />
                      <MovementInput
                        id="movementValue"
                        title="Valor (R$)"
                        isValue
                        placeholder="Insira o valor"
                        value={formData.value}
                        setValue={(value) => handleValueChange(value)}
                      />

                      <CustomButton
                        id="editSubmit"
                        title="Atualizar"
                        isSubmit
                        disabled={isSubmitting}
                      />
                    </form>
                  </Modal>

                  <ConfirmModal
                    toggleButton={
                      <button
                        type="button"
                        className="duration-500 hover:brightness-75 text-red-500"
                      >
                        <Trash2 size={18} />
                      </button>
                    }
                    text="Tem certeza que deseja excluir esta movimentação?"
                    extraText={
                      <>
                        {option?.name} -{" "}
                        {option?.date.split("-").reverse().join("/")} -{" "}
                        {parseFloat(option?.value).toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </>
                    }
                    onConfirm={() =>
                      handleDeleteMovement(
                        option?.type === "Entrada"
                          ? "entry"
                          : option?.type === "Saída"
                          ? "exit"
                          : "reservation",
                        option?.timeStamp
                      )
                    }
                  />
                </div>

                <div className="w-full flex items-end justify-between gap-4">
                  <div className="flex items-center gap-1">
                    <span
                      className={`font-bold text-sm truncate 2xl:text-base ${
                        option?.type === "Entrada"
                          ? "text-green-500"
                          : option?.type === "Saída"
                          ? "text-red-500"
                          : "text-yellow-500"
                      }`}
                    >
                      {parseFloat(
                        option?.type === "Saída" ||
                          option?.reservationType === "Saída"
                          ? option?.value * -1
                          : option?.value
                      ).toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </span>
                  </div>

                  <div className="flex flex-col items-end gap-1">
                    <div
                      className={`hidden items-center gap-1 lg:flex ${
                        option?.type === "Entrada"
                          ? "text-green-500"
                          : option?.type === "Saída"
                          ? "text-red-500"
                          : "text-yellow-500"
                      }`}
                    >
                      {option?.type === "Entrada" ? (
                        <TrendingUp size={18} />
                      ) : option?.type === "Saída" ? (
                        <TrendingDown size={18} />
                      ) : (
                        <PiggyBank size={18} />
                      )}

                      <span className="font-bold text-xs truncate">
                        {option?.reservationType || option?.type}
                      </span>
                    </div>

                    {option?.type === "Saída" && (
                      <div className="flex items-center gap-1 text-easyGreen">
                        <FinancialTypeIcon
                          financialType={option?.financialType}
                        />
                        <span className="text-xs">{option?.financialType}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="w-full flex flex-col gap-4 items-center">
              <p className="text-white text-sm text-center text-clip">
                Nenhum item encontrado...
              </p>

              <button
                onClick={() => {
                  setFilters({
                    type: "Todos",
                    category: "Todas",
                    order: "Tempo",
                    searchTerm: "",
                    date: "Qualquer",
                  });
                }}
                className="text-easyBlack duration-500 bg-easyGreen w-fit flex items-center justify-between 
                    gap-2 rounded-lg text-sm px-4 py-2 text-center font-semibold hover:brightness-75 focus:outline-none"
              >
                Limpar Filtros
              </button>
            </div>
          )}
        </div>

        {currentItems.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onNext={goToNextPage}
            onPrevious={goToPreviousPage}
          />
        )}
      </div>
    )
  );
}
