import { ref, onValue } from "firebase/database";
import { db } from "../services/firebase";
import toast from 'react-hot-toast';

const readManutention = (setManutention, setManutentionForecast) => {
  const userDbRef = ref(db, "appSettings/manutention");

  return onValue(
    userDbRef,
    (snapshot) => {
      const data = snapshot.val();
      setManutention(data.isActive);
      setManutentionForecast(data.endForecast)
    },
    (error) => {
      toast.error("Erro ao obter dados de manutenção.");
    }
  );
};

export { readManutention };