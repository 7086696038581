import { useState } from "react";
import SignIn from "./signIn";
import Register from "./register";
import ForgotPassword from "./forgotPassword";

export default function FormConstroller() {
  const [formSelected, changeForm] = useState(0);
  const [animationClass, setAnimationClass] = useState("");

  const handleChangeForm = (formNumber) => {
    setAnimationClass("duration-500 translate-x-[-500px] opacity-0 ease-in");
    setTimeout(() => {
      changeForm(formNumber);
      setAnimationClass("translate-x-[500px]");
      setTimeout(() => {
        setAnimationClass("duration-500 translate-x-0 ease-out");
      }, 200);
    }, 550);
  };

  return (
    <div
      className="w-full justify-center overflow-x-hidden sm:bg-white sm:rounded-2xl
      flex flex-col sm:p-8 sm:max-w-xs sm:min-h-[500px] sm:max-h-[500px] sm:shadow-md"
    >
      {formSelected === 0 ? (
        <SignIn
          animationClassName={animationClass}
          handleChangeForm={handleChangeForm}
        />
      ) : formSelected === 1 ? (
        <Register
          animationClassName={animationClass}
          handleChangeForm={handleChangeForm}
        />
      ) : (
        <ForgotPassword
          animationClassName={animationClass}
          handleChangeForm={handleChangeForm}
        />
      )}
    </div>
  );
}
