import toast from "react-hot-toast";
import { readMovement } from "../movement";

// Função para extrair as datas, encontrar meses únicos e formatá-los
const processDates = (movement) => {
  const getValues = () => movement ? Object.values(movement) : [];
  const dates = ['entry', 'exit', 'reservation'].flatMap(getValues).map((item) => item.date);

  const uniqueMonths = [...new Set(dates.map((date) => date.slice(0, 7)))];

  return uniqueMonths.map((date) => {
    const [year, month] = date.split("-");
    return `${month}/${year.slice(2)}`;
  });
};

// Função para calcular os saldos das movimentações para um mês específico
const calculateAmounts = (allMovement, monthSelected, monthsFound) => {
  const getPreviousSaldo = () => {
    let sum = 0;
    for (let i = 0; i < monthsFound.indexOf(monthSelected); i++) {
      const month = monthsFound[i];
      sum = calculateAmounts(allMovement, month, monthsFound)?.saldos?.pop() || sum;
    }
    return sum;
  };

  const filteredMovements = allMovement.flat().filter((movement) => {
    const [year, month] = movement?.date.split("-");
    return monthSelected === `${month}/${year.slice(2)}`;
  });

  const dailyBalances = {};
  let currentBalance = getPreviousSaldo();

  filteredMovements.sort((a, b) => new Date(a.date) - new Date(b.date));

  filteredMovements.forEach((movement) => {
    const formattedDate = new Date(movement.date).toISOString().slice(0, 10).split("-").reverse().slice(0, 2).join("/");
    currentBalance += parseFloat(movement.value) * (movement.type === "Entrada" || (movement.type === "Reserva" && movement.reservationType === "Saída") ? 1 : -1);
    dailyBalances[formattedDate] = currentBalance;
  });

  const [selectedMonth, selectedYear] = monthSelected.split("/");
  const daysInMonth = new Date(`20${selectedYear}`, parseInt(selectedMonth, 10), 0).getDate();
  const today = new Date();
  const isCurrentMonth = selectedYear === today.getFullYear().toString().slice(2) && selectedMonth === String(today.getMonth() + 1).padStart(2, "0");

  const datas = [];
  const saldos = [];
  for (let day = 1; day <= daysInMonth; day++) {
    if (isCurrentMonth && day > today.getDate()) break;
    const dayStr = String(day).padStart(2, "0");
    const date = `${dayStr}/${selectedMonth}/${selectedYear}`;
    saldos.push(dailyBalances[`${dayStr}/${selectedMonth}`] || currentBalance);
    datas.push(date);
  }

  return { datas, saldos };
};

// Função principal para calcular o saldo atual e as reservas atuais
const calculateBalances = async () => {
  try {
    const movement = await readMovement();
    const getEntries = (type) => {
      return Object.values(movement).flat().filter(item => item.type === type);
    };

    const monthsFound = processDates(movement);

    const { saldos } = calculateAmounts(movement, monthsFound.at(-1), monthsFound);
    const currentBalance = Math.round(saldos.pop() * 100) / 100;

    const currentReservation = getEntries('Reserva').reduce((total, movement) => {
      return total + parseFloat(movement.value) * (movement.reservationType === "Saída" ? -1 : 1);
    }, 0);
    const roundedReservation = Math.round(currentReservation * 100) / 100;

    return { currentBalance, currentReservation: roundedReservation };
  } catch (error) {
    toast.error("Ocorreu um erro ao buscar o saldo atual.");
    return null;
  }
};



export { calculateBalances };
