import React from "react";

const ComparisonByType = ({ movements, uniqueMonths }) => {
  const types = ["Entrada", "Saída", "Reserva"];

  // Função para calcular o saldo acumulado da reserva até um determinado mês
  const calculateAccumulatedReserve = (movements, month) => {
    return movements
      .filter((movement) => movement.type === "Reserva")
      .reduce((total, movement) => {
        const movementMonth = movement.date.substring(0, 7);
        if (movementMonth <= month) {
          const value = parseFloat(movement.value);
          total += value * (movement.reservationType === "Saída" ? -1 : 1);
        }
        return total;
      }, 0)
      .toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
  };

  // Função para calcular o total por tipo e mês (Entrada e Saída)
  const calculateTotalByTypeAndMonth = (movements, type, month) => {
    if (type === "Reserva")
      return calculateAccumulatedReserve(movements, month);

    return movements
      .reduce((total, movement) => {
        const movementMonth = movement.date.substring(0, 7);
        if (movement.type === type && movementMonth === month) {
          total += parseFloat(movement.value);
        }
        return total;
      }, 0)
      .toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
  };

  // Função para calcular o saldo acumulado final da reserva
  const calculateCurrentReserveBalance = (movements) => {
    const total = movements
      .filter((movement) => movement.type === "Reserva")
      .reduce((total, movement) => {
        const value = parseFloat(movement.value);
        return total + value * (movement.reservationType === "Saída" ? -1 : 1);
      }, 0);

    return (Math.round(total * 100) / 100).toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  };

  // Função para calcular o total por tipo
  const calculateTotalByType = (movements, type) => {
    // if (type === "Reserva") return calculateCurrentReserveBalance(movements);
    if (type === "Reserva") return "-";

    return movements
      .filter((movement) => movement.type === type)
      .reduce((total, movement) => total + parseFloat(movement.value), 0)
      .toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
  };

  return (
    <div className="w-full overflow-x-auto">
      <table className="w-full text-center rounded-md overflow-hidden">
        <thead className="text-sm uppercase bg-easyBlueLight text-white">
          <tr>
            <th scope="col" className="px-2 py-3">
              Data
            </th>
            {types.map((type) => (
              <th
                key={type}
                scope="col"
                className="px-2 py-3 border-l border-white"
              >
                {type}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {uniqueMonths.map((month) => (
            <tr
              key={month}
              className="border-y text-sm text-white/90 bg-easyBlue border-white duration-500 hover:bg-easyBlueLight whitespace-nowrap"
            >
              <th scope="row" className="px-4 py-2 font-semibold text-white">
                <span className="capitalize">
                  {new Date(`${month}-02`).toLocaleDateString("pt-BR", {
                    month: "short",
                  })}
                </span>
                <span className="ml-1">{new Date(month).getFullYear()}</span>
              </th>
              {types.map((type) => (
                <td
                  key={`${month}-${type}`}
                  className="px-4 py-2 border-l border-white"
                >
                  {calculateTotalByTypeAndMonth(movements, type, month)}
                </td>
              ))}
            </tr>
          ))}
          <tr className="text-sm text-white font-semibold bg-easyBlueLight duration-500 hover:bg-easyBlueLight/50">
            <th
              scope="row"
              className="px-4 py-2 font-semibold whitespace-nowrap"
            >
              Totais
            </th>
            {types.map((type) => (
              <td
                key={`total-${type}`}
                className="px-4 py-2 border-l border-white"
              >
                {calculateTotalByType(movements, type)}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ComparisonByType;
