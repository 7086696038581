import { X } from "lucide-react";
import React, { useState } from "react";

export default function ConfirmModal({
  toggleButton,
  text,
  extraText,
  onConfirm,
  onCancel,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {/* Toggle button passed as a prop */}
      <div onClick={toggleModal}>{toggleButton}</div>
      {/* Overlay and Modal structure */}
      {isOpen && (
        <>
          {/* Overlay */}
          <div
            className="fixed inset-0 bg-black/80"
            onClick={() => {
              onCancel();
              toggleModal();
            }}
          />
          {/* Modal */}
          <div
            id="popup-modal"
            tabIndex="-1"
            className="fixed top-0 right-0 left-0 z-[500] flex justify-center items-center 
            w-full h-dvh max-h-full overflow-y-auto overflow-x-hidden"
          >
            <div className="relative p-4 w-full max-w-md max-h-full">
              <div className="relative bg-white rounded-lg shadow">
                <button
                  onClick={toggleModal}
                  type="button"
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent 
                  hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 
                  inline-flex justify-center items-center"
                >
                  <X />
                  <span className="sr-only">Close modal</span>
                </button>
                <div className="p-4 text-center flex flex-col items-center gap-4">
                  {/* <MessageCircleWarning className="mx-auto text-gray-400 w-12 h-12" /> */}

                  <div className="w-[100px] sm:max-w-[150px]">
                    <img
                      src="/illustrations/alert.gif"
                      alt="Ilustração gráficos"
                      className="w-full h-full object-cover"
                    />
                  </div>

                  <div className="flex flex-col items-center gap-2">
                    {extraText && (
                      <h4 className="font-semibold text-sm text-easyGreen">
                        {extraText}
                      </h4>
                    )}

                    <h3 className="text-gray-500">{text}</h3>
                  </div>

                  <div className="flex items-center gap-4">
                    <button
                      onClick={() => {
                        onConfirm && onConfirm();
                        toggleModal();
                      }}
                      type="button"
                      className="text-white bg-easyGreen duration-300 hover:brightness-75 focus:outline-none 
                      font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
                    >
                      Confirmar
                    </button>

                    <button
                      onClick={() => {
                        onCancel && onCancel();
                        toggleModal();
                      }}
                      type="button"
                      className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none duration-300
                    bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-red-500 focus:z-10"
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
