import React from "react";
import { useNavigate } from "react-router-dom";

export default function ConstructionPage() {
  const navigate = useNavigate();

  return (
    <section
      className="w-full max-w-[90%] mx-auto min-h-dvh max-h-dvh overflow-x-hidden flex flex-col gap-4 
      md:max-h-[calc(100dvh-56px)] md:max-w-none items-center justify-center"
    >
      <div className="w-[150px]">
        <img
          src="/illustrations/manutention.gif"
          alt="Ilustração gráficos"
          className="w-full h-full object-cover"
        />
      </div>

      <h2 className="font-bold text-white text-2xl text-center">
        Página Em Manutenção!
      </h2>

      <p className="font-semibold text-white/80 text-sm max-w-lg text-center">
        Estamos trabalhando duro para trazer a você uma nova maneira de dominar
        suas finanças e conquistar seus sonhos!
      </p>

      <button
        onClick={() => navigate("/home")}
        className="mt-4 px-6 py-2 bg-easyGreen text-easyBlue font-semibold rounded-lg 
        hover:bg-easyGreen/80 transition duration-300"
      >
        Voltar para a Página Inicial
      </button>
    </section>
  );
}
