import React, { useEffect, useState } from "react";

export default function AmountPerDay({ movements }) {
  const financeQuotes = [
    "Simplifique sua vida financeira com Easy Finanças!",
    "Acompanhe seus gastos e conquiste seus objetivos!",
    "Descomplique suas finanças com apenas um clique!",
    "Invista seu tempo e dinheiro com inteligência hoje!",
    "Transforme desafios financeiros em oportunidades de crescimento!",
    "Controle suas despesas e realize seus sonhos!",
    "Planeje seu futuro financeiro com confiança e clareza!",
    "Cada decisão financeira é um passo para o sucesso!",
    "Seu dinheiro, suas escolhas: faça valer a pena!",
    "A gestão financeira é fácil com Easy Finanças!",
    "Organize suas finanças e sinta-se mais seguro!",
    "Torne suas finanças simples e compreensíveis agora!",
    "Cresça sua riqueza com decisões financeiras inteligentes!",
    "Acompanhe seu progresso financeiro de forma fácil!",
    "Economize dinheiro e viva com mais liberdade!",
    "Cada centavo economizado é uma conquista importante!",
    "Tenha controle total sobre suas finanças pessoais!",
    "Transforme sua visão financeira em realidade agora!",
    "Descubra o prazer de uma vida financeira equilibrada!",
    "Invista sabiamente e veja seu futuro brilhar!",
    "Administre seu orçamento com sabedoria e segurança!",
    "Siga em frente: sua liberdade financeira começa aqui!",
    "Construa um futuro financeiro sólido e promissor!",
    "Visualize suas metas financeiras e alcance-as facilmente!",
    "Transforme seus hábitos financeiros em conquistas reais!",
    "Economize e invista: o futuro é seu!",
    "Desperte seu potencial financeiro com Easy Finanças!",
    "Cada passo conta: avance em direção à estabilidade!",
    "Organização financeira é a chave para a liberdade!",
    "Tome decisões financeiras com confiança e clareza!",
    "Planeje suas finanças e viva com tranquilidade!",
    "O sucesso financeiro começa com escolhas inteligentes!",
    "Seja proativo e cuide do seu futuro financeiro!",
    "Invista no seu conhecimento financeiro para prosperar!",
    "Acompanhe suas finanças e mantenha-se motivado!",
    "Simples passos para um controle financeiro eficaz!",
    "Seus objetivos financeiros são possíveis e alcançáveis!",
    "Simplicidade na gestão do dinheiro traz paz!",
    "Construa hábitos financeiros saudáveis com Easy Finanças!",
    "Administre seu dinheiro com facilidade e eficiência!",
    "A liberdade financeira começa com um bom planejamento!",
    "Dê o primeiro passo para sua estabilidade financeira!",
    "Torne-se o mestre das suas finanças pessoais!",
    "Desperte a sua consciência financeira diariamente!",
    "Cresça financeiramente com informações e planejamento!",
    "O futuro financeiro brilha com boas decisões!",
    "Equilibre seus gastos e aumente suas economias!",
    "Sua jornada financeira começa com uma escolha!",
    "Acredite em você e no seu potencial financeiro!",
  ];

  const chooseRandomNumber = (limit) => Math.floor(Math.random() * limit);

  const [quote, setQuote] = useState("");

  useEffect(() => {
    const randomIndex = chooseRandomNumber(financeQuotes.length);
    setQuote(financeQuotes[randomIndex]);
  }, []);

  return (
    <div
      className="w-full rounded-2xl border border-white bg-white/5 p-4 flex 
      flex-row gap-4 items-center justify-center md:max-h-20 bgEasyLogo overflow-hidden"
    >
      <div className="w-44 md:w-16 lg:hidden">
        <img
          src="/logo/noBgNoText.png"
          alt="Easy Finanças"
          className="w-full h-full object-cover"
        />
      </div>

      <p className="text-center font-semibold text-easyGreen min-[400px]:text-lg md:text-xl">
        {quote}
      </p>
    </div>
  );
}

// // Recuperando a data atual do usuário
// const currentDate = new Date();
// const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, "0");
// const currentYear = currentDate.getFullYear().toString();
// const currentDay = currentDate.getDate().toString().padStart(2, "0");
// const currentYearMonth = `${currentYear}-${currentMonth}`;

// // Criando um array de datas no formato "DD/MM/YY" para os dias do mês atual
// const daysOfMonth = [];
// for (let i = 1; i <= parseInt(currentDay, 10); i++) {
//   const dayString = i.toString().padStart(2, "0");
//   daysOfMonth.push(`${dayString}/${currentMonth}/${currentYear.slice(-2)}`);
// }

// // Função para calcular os saldos acumulados até o mês anterior
// const calculatePreviousMonthsBalance = () => {
//   let previousMonthsBalance = 0;

//   // Iterar sobre os meses anteriores ao mês atual
//   for (let m = 1; m < parseInt(currentMonth, 10); m++) {
//     const monthString = m.toString().padStart(2, "0");
//     const yearMonth = `${currentYear}-${monthString}`;

//     // Filtrar movimentos para o mês e ano específicos
//     const monthMovements = movements.filter(({ date }) =>
//       date.startsWith(yearMonth)
//     );

//     // Calcular saldo para o mês específico
//     const monthBalance = monthMovements.reduce(
//       (balance, { type, reservationType, value }) => {
//         const multiplier =
//           type === "Entrada" ||
//           (type === "Reserva" && reservationType === "Saída")
//             ? 1
//             : -1;
//         return balance + multiplier * parseFloat(value);
//       },
//       0
//     );

//     // Acumular saldo do mês ao saldo dos meses anteriores
//     previousMonthsBalance += monthBalance;
//   }

//   return previousMonthsBalance;
// };

// // Calculando o saldo dos meses anteriores até o mês anterior ao atual
// const previousMonthsBalance = calculatePreviousMonthsBalance();

// // Filtrando os movimentos para o mês atual até o dia atual do usuário
// const filteredMovements = movements.filter(({ date }) => {
//   const [movementYear, movementMonth, movementDay] = date.split("-");
//   return (
//     `${movementYear}-${movementMonth}` === currentYearMonth &&
//     parseInt(movementDay) <= parseInt(currentDay)
//   );
// });

// // Calculando os saldos para cada dia do mês atual, incluindo o saldo dos meses anteriores
// const dailyBalances = daysOfMonth.map((day, index) => {
//   const dayMovements = filteredMovements.filter(
//     ({ date }) => date === `${currentYearMonth}-${day.slice(0, 2)}`
//   );

//   const dailyBalance = dayMovements.reduce(
//     (balance, { type, reservationType, value }) => {
//       const multiplier =
//         type === "Entrada" ||
//         (type === "Reserva" && reservationType === "Saída")
//           ? 1
//           : -1;
//       return balance + multiplier * parseFloat(value);
//     },
//     0
//   );

//   // Adicionando o saldo dos meses anteriores ao saldo do dia 1
//   if (index === 0) {
//     return (dailyBalance + previousMonthsBalance).toFixed(2);
//   } else {
//     return dailyBalance.toFixed(2);
//   }
// });

// // Transformando os saldos diários em valores acumulados
// const RealDailyBalances = dailyBalances.map((balance, index) => {
//   if (index === 0) {
//     return parseFloat(balance).toFixed(2);
//   } else {
//     const accumulatedBalance = dailyBalances
//       .slice(0, index + 1)
//       .map((balance) => parseFloat(balance))
//       .reduce((acc, curr) => acc + curr, 0);

//     return accumulatedBalance.toFixed(2);
//   }
// });

// // Calculando os saldos para o mês anterior
// const previousMonthBalances = daysOfMonth.map((day, index) => {
//   // Calculando o mês anterior
//   const previousMonthDate = new Date(currentDate);
//   previousMonthDate.setMonth(previousMonthDate.getMonth() - 1);
//   const previousMonth = (previousMonthDate.getMonth() + 1)
//     .toString()
//     .padStart(2, "0");
//   const previousYear = previousMonthDate.getFullYear().toString();

//   // Calculando o mês retrasado
//   const twoMonthsAgoDate = new Date(currentDate);
//   twoMonthsAgoDate.setMonth(twoMonthsAgoDate.getMonth() - 2);
//   const twoMonthsAgoMonth = (twoMonthsAgoDate.getMonth() + 1)
//     .toString()
//     .padStart(2, "0");
//   const twoMonthsAgoYear = twoMonthsAgoDate.getFullYear().toString();

//   const dayMovements = movements.filter(
//     ({ date }) =>
//       date === `${previousYear}-${previousMonth}-${day.slice(0, 2)}`
//   );

//   const dailyBalance = dayMovements.reduce(
//     (balance, { type, reservationType, value }) => {
//       const multiplier =
//         type === "Entrada" ||
//         (type === "Reserva" && reservationType === "Saída")
//           ? 1
//           : -1;
//       return balance + multiplier * parseFloat(value);
//     },
//     0
//   );

//   // Adicionando o saldo do mês retrasado ao saldo do dia 1 do mês passado
//   if (index === 0) {
//     const previousMonthsDayMovements = movements.filter(({ date }) =>
//       date.startsWith(`${twoMonthsAgoYear}-${twoMonthsAgoMonth}`)
//     );

//     const previousMonthsBalance = previousMonthsDayMovements.reduce(
//       (balance, { type, reservationType, value }) => {
//         const multiplier =
//           type === "Entrada" ||
//           (type === "Reserva" && reservationType === "Saída")
//             ? 1
//             : -1;
//         return balance + multiplier * parseFloat(value);
//       },
//       0
//     );

//     return (dailyBalance + previousMonthsBalance).toFixed(2);
//   } else {
//     return dailyBalance.toFixed(2);
//   }
// });

// // Transformando os saldos do mês anterior em valores acumulados
// const RealPreviousMonthBalances = previousMonthBalances.map(
//   (balance, index) => {
//     if (index === 0) {
//       return parseFloat(balance).toFixed(2);
//     } else {
//       const accumulatedBalance = previousMonthBalances
//         .slice(0, index + 1)
//         .map((balance) => parseFloat(balance))
//         .reduce((acc, curr) => acc + curr, 0);

//       return accumulatedBalance.toFixed(2);
//     }
//   }
// );

// <ApexChart
//     categories={daysOfMonth}
//     series={[
//       { name: "Saldo Mês Atual", data: RealDailyBalances },
//       { name: "Saldo Mês Anterior", data: RealPreviousMonthBalances },
//     ]}
//     type="line"
//     dashed={[0, 10]}
//     height="120px"
//     stroke={4}
//     colors={["#1ce3c7", "#ffa900"]}
//     onlyChart
//   />
