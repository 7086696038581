import { ChevronLeft, ChevronRight } from "lucide-react";

export default function Pagination({
  currentPage,
  totalPages,
  onNext,
  onPrevious,
}) {
  return (
    <div className="w-full flex justify-center items-center gap-4">
      <button onClick={onPrevious} className="duration-500 hover:brightness-75">
        <ChevronLeft className="text-easyGreen drop-shadow-[0_2px_5px_#1ce3c7] duration-500 hover:drop-shadow-none" />
      </button>

      <span className="text-sm font-semibold text-easyGreen">
        Página {currentPage} de {totalPages}
      </span>

      <button onClick={onNext} className="duration-500 hover:brightness-75">
        <ChevronRight className="text-easyGreen drop-shadow-[0_2px_5px_#1ce3c7] duration-500 hover:drop-shadow-none" />
      </button>
    </div>
  );
}
