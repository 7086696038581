import { useNavigate } from "react-router-dom";
import PrivateLayout from "../../privateLayout";
import CustomButton from "../../buttons/customButton";

export default function Home() {
  const navigate = useNavigate();

  return (
    <PrivateLayout>
      <section
        className="w-full mx-auto h-[calc(100dvh-54px)] overflow-x-hidden pt-4 pb-6 md:flex-row bgEasy
        md:max-h-[calc(100dvh-54px)] md:px-8 md:pb-4 relative flex justify-center md:justify-normal px-4 flex-col gap-4"
      >
        <div className="w-[150px] md:w-full md:max-w-[400px] mx-auto flex items-center md:order-2 md:mx-0">
          <img
            src="/illustrations/phone.gif"
            alt="Ilustração celular com foguete"
            className="w-full h-full object-contain"
          />
        </div>

        <div className="flex flex-col gap-4 w-full md:max-w-lg">
          <h2 className="font-bold text-easyGreen text-center md:text-left text-xl md:text-3xl lg:text-4xl">
            Veja onde está o seu dinheiro de forma fácil!
          </h2>

          <p className="text-white text-sm max-w-lg text-center md:text-left md:text-base">
            Nosso app mostra tudo sobre seu dinheiro: quanto você gasta, quanto
            ganha e o quanto tem guardado. Tudo em um lugar só, sem confusão!
            Assim, você sempre sabe onde está o seu dinheiro, como se fosse um
            cofrinho virtual. Simples assim!
          </p>

          <div className="max-w-min mx-auto md:mx-0">
            <CustomButton
              id="dashBtn"
              title="Dashboard"
              functionToExecute={() => navigate("/dashboard")}
            />
          </div>
        </div>
      </section>
    </PrivateLayout>
  );
}
