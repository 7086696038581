import React from "react";
import ApexChart from "../../../ApexChart";
import ChartContainer from "./chartContainer";

export default function ExpensesByDay({ movements }) {
  function getDayOfWeekExpenses(allMovements) {
    const dayOfWeekExpenses = Array.from({ length: 7 }, () => 0);

    allMovements.flat().forEach((movement) => {
      if (movement?.type === "Saída") {
        const date = new Date(movement.date + "T00:00:00-03:00");

        const dayOfWeek = date.getDay();

        dayOfWeekExpenses[dayOfWeek] += parseFloat(movement.value) || 0;
      }
    });

    return dayOfWeekExpenses;
  }

  const dayOfWeekExpenses = getDayOfWeekExpenses(movements);

  return (
    <ChartContainer title={"Despesas por Dia da Semana"}>
      <div className="w-full -mt-6">
        <ApexChart
          categories={[
            "Domingos",
            "Segundas-feiras",
            "Terças-feiras",
            "Quartas-feiras",
            "Quintas-feiras",
            "Sextas-feiras",
            "Sábados",
          ]}
          series={[
            {
              name: "Total Gasto",
              data: dayOfWeekExpenses,
            },
          ]}
          hasLegend
          type="bar"
          colors={[
            "#ffbd3d",
            "#00cf44",
            "#993399",
            "#ec0000",
            "#0A6847",
            "#FC4100",
            "#5a9cff",
          ]}
          isHorizontal
          height="315px"
        />
      </div>
    </ChartContainer>
  );
}
