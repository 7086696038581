import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { calculateBalances } from "../../utils/calculations/currentBalances";
import { addMovement } from "../../utils/movement";
import Loading from "../screenAlerts/loading";
import Dropdown from "../dropdown";
import MovementInput from "../movement/movementInput";
import CustomButton from "../buttons/customButton";
import PrivateLayout from "../privateLayout";

export default function Movement() {
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    type: "",
    name: "",
    date: "",
    financialType: "",
    reservationType: "",
    value: 0,
  });

  const handleValueChange = (value) => {
    if (/^\d*[.,]?\d*$/.test(value)) {
      const formattedValue = value.replace(",", ".");
      setFormData({
        ...formData,
        value: formattedValue,
      });
    }
  };

  const handleAddMovement = async () => {
    setIsSubmitting(true);

    if (
      formData.name === "" ||
      formData.date === "" ||
      formData.value === "" ||
      formData.type === ""
    ) {
      toast.error("Por favor, preencha todos os campos.");
      setIsSubmitting(false);
      return;
    }
    if (formData.value == "0") {
      toast.error("Por favor, preencha o valor.");
      setIsSubmitting(false);
      return;
    }
    if (formData.type === "Saída" && formData.financialType === "") {
      toast.error("Por favor, preencha o tipo de gasto.");
      setIsSubmitting(false);
      return;
    }
    if (formData.type === "Reserva" && formData.reservationType === "") {
      toast.error("Por favor, preencha o tipo de reserva.");
      setIsSubmitting(false);
      return;
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const movementDate = new Date(formData.date + "T00:00:00");

    if (movementDate > today) {
      toast.error("A data não pode estar no futuro.");
      setIsSubmitting(false);
      return;
    }

    const checkBalance = async (balanceKey, threshold) => {
      const balances = await calculateBalances();
      const actualBalance = balances ? balances[balanceKey] : null;
      if (
        actualBalance === null ||
        actualBalance - parseFloat(formData.value) < threshold
      ) {
        toast.error("Você não tem saldo suficiente.");
        setIsSubmitting(false);
        return true;
      }
      return false;
    };

    const sendMovement = async () => {
      if (
        formData.type === "Saída" ||
        (formData.type === "Reserva" && formData.reservationType === "Entrada")
      ) {
        if (await checkBalance("currentBalance", 0)) return;
      } else if (
        formData.type === "Reserva" &&
        formData.reservationType === "Saída"
      ) {
        if (await checkBalance("currentReservation", 0)) return;
      }

      const movementType =
        formData.type === "Saída"
          ? "exit"
          : formData.type === "Entrada"
          ? "entry"
          : "reservation";

      await addMovement(movementType, formData);

      const today = new Date();
      const formattedDate = `${today.getFullYear()}-${String(
        today.getMonth() + 1
      ).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`;
      setFormData({
        type: "",
        name: "",
        date: formattedDate,
        financialType: "",
        reservationType: "",
        value: 0,
      });
      setIsSubmitting(false);
    };

    sendMovement();
  };

  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    setFormData((prevData) => ({
      ...prevData,
      date: formattedDate,
    }));
    setLoading(false);
  }, []);

  const handleDropdownChange = (fieldName, value) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const dropdownOptionsType = ["Entrada", "Saída", "Reserva"];
  const dropdownOptionsFinancialType = [
    "Alimentação",
    "Crédito",
    "Lazer",
    "Mensalidades",
    "Pets",
    "Saúde",
    "Transporte",
    "Vestuário",
    "Outros",
  ];
  const dropdownOptionsReservation = ["Entrada", "Saída"];

  return (
    <PrivateLayout>
      <section
        className="w-full h-[calc(100dvh-56px)] overflow-x-hidden px-4 pt-4 pb-6 
        md:max-h-[calc(100dvh-56px)] md:pb-4 relative bgEasy"
      >
        {loading ? (
          <Loading height={"h-[calc(100dvh-56px)]"} />
        ) : (
          <div className="flex gap-4 xl:gap-20">
            <div className="w-full flex flex-col gap-4 md:max-w-2xl">
              <div className="flex flex-col gap-4 w-full md:flex-row md:justify-between">
                <div className="flex flex-col">
                  <h2 className="text-xl text-easyGreen font-bold">
                    Movimentação
                  </h2>
                  <h3 className="text-gray-400 text-xs">
                    Adicionar movimentações
                  </h3>
                </div>

                <div className="w-[150px] self-center">
                  <img
                    src="/illustrations/pay.gif"
                    alt="Ilustração pagamento"
                    className="w-full h-full object-cover"
                  />
                </div>
              </div>

              <div className="flex flex-col gap-4 md:flex-row md:items-end md:justify-between">
                <form className="w-full flex flex-col gap-4 md:max-w-xs">
                  <div className="md:hidden flex items-center justify-between gap-4">
                    <Dropdown
                      title={"Tipo De Movimentação"}
                      mandatory
                      selected={
                        formData.type === "" ? "Selecionar" : formData.type
                      }
                      options={dropdownOptionsType}
                      onChange={(value) => handleDropdownChange("type", value)}
                    />

                    {formData.type === "Saída" && (
                      <Dropdown
                        title={"Tipo De Gasto"}
                        mandatory
                        selected={
                          formData.financialType === ""
                            ? "Selecionar"
                            : formData.financialType
                        }
                        options={dropdownOptionsFinancialType}
                        onChange={(value) =>
                          handleDropdownChange("financialType", value)
                        }
                      />
                    )}

                    {formData.type === "Reserva" && (
                      <Dropdown
                        title={"Tipo De Reserva"}
                        mandatory
                        selected={
                          formData.reservationType === ""
                            ? "Selecionar"
                            : formData.reservationType
                        }
                        options={dropdownOptionsReservation}
                        onChange={(value) =>
                          handleDropdownChange("reservationType", value)
                        }
                      />
                    )}
                  </div>

                  <MovementInput
                    id="movementName"
                    title="Nome Da Movimentação"
                    mandatory
                    placeholder="Insira o nome"
                    value={formData.name}
                    setValue={(value) =>
                      setFormData({ ...formData, name: value })
                    }
                  />

                  <MovementInput
                    id="movementDate"
                    title="Data"
                    date
                    mandatory
                    placeholder="Insira o nome"
                    value={formData.date}
                    setValue={(value) =>
                      setFormData({ ...formData, date: value })
                    }
                  />

                  <MovementInput
                    id="movementValue"
                    title="Valor (R$)"
                    mandatory
                    isValue
                    placeholder="Insira o valor"
                    value={formData.value}
                    setValue={(value) => handleValueChange(value)}
                  />
                </form>

                <div className="md:w-40 flex flex-col justify-between h-full">
                  <div className="hidden md:flex flex-col gap-4">
                    <Dropdown
                      title={"Tipo De Movimentação"}
                      mandatory
                      selected={
                        formData.type === "" ? "Selecionar" : formData.type
                      }
                      options={dropdownOptionsType}
                      onChange={(value) => handleDropdownChange("type", value)}
                    />

                    {formData.type === "Saída" && (
                      <Dropdown
                        title={"Tipo De Gasto"}
                        mandatory
                        selected={
                          formData.financialType === ""
                            ? "Selecionar"
                            : formData.financialType
                        }
                        options={dropdownOptionsFinancialType}
                        onChange={(value) =>
                          handleDropdownChange("financialType", value)
                        }
                      />
                    )}

                    {formData.type === "Reserva" && (
                      <Dropdown
                        title={"Tipo De Reserva"}
                        mandatory
                        selected={
                          formData.reservationType === ""
                            ? "Selecionar"
                            : formData.reservationType
                        }
                        options={dropdownOptionsReservation}
                        onChange={(value) =>
                          handleDropdownChange("reservationType", value)
                        }
                      />
                    )}
                  </div>

                  <CustomButton
                    id="movementSubmit"
                    title="Adicionar"
                    disabled={isSubmitting}
                    functionToExecute={() => handleAddMovement()}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </PrivateLayout>
  );
}
