export default function MovementInput({
  id,
  title,
  placeholder,
  mandatory,
  date,
  search,
  value,
  setValue,
  isValue,
}) {
  return (
    <div className="w-full">
      <label
        htmlFor={id}
        className="mb-2 text-sm font-medium text-white flex items-center gap-1"
      >
        <span>{title}</span>
        <span>{mandatory && <span className="text-red-500">*</span>}</span>
        {isValue && !isNaN(parseFloat(value)) && isFinite(value) && (
          <span className="text-white/50 text-xs ml-auto">
            {parseFloat(value).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })}
          </span>
        )}
      </label>
      <div
        className="relative overflow-hidden flex items-center bg-easyBlueLight border border-[#4c50a2] text-white text-sm rounded-lg w-full
        placeholder-gray-400 focus:outline-none
        "
      >
        <input
          type={date ? "date" : search ? "search" : "text"}
          id={id}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className="w-full px-2 py-2.5 text-white border-none focus:outline-none bg-transparent"
          placeholder={placeholder}
          {...(mandatory ? { required: true } : null)}
        />
      </div>
    </div>
  );
}
