import { handleLogout } from "../../utils/loginAndRegister";
import { auth } from "../../services/firebase";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Bot,
  ChartSpline,
  HandCoins,
  LogOut,
  PiggyBank,
  Plus,
  User,
} from "lucide-react";

export default function Navigation() {
  const location = useLocation();
  const navigate = useNavigate();

  function getEmoji() {
    const financeEmojis = ["💰", "💸", "💲", "❤️", "📊", "🏦", "⭐"];

    const dayOfMonth = new Date().getDate();
    const index = Math.min(
      Math.floor((dayOfMonth - 1) / 5),
      financeEmojis.length - 1
    );

    return financeEmojis[index];
  }

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location]);

  return (
    <nav
      className="w-full fixed z-[51] bottom-0 left-0 bg-easyBlue rounded-t-xl border-t border-t-easyGreen py-3 
      md:relative md:w-52 md:border-none md:rounded-none md:z-0 md:min-h-dvh md:min-w-52 md:max-w-52"
    >
      <div className="flex gap-2 items-center px-4">
        <div className="hidden w-14 md:block">
          <img
            src="/logo/noBgNoText.png"
            alt="Ilustração gráficos"
            className="w-full h-full object-cover"
          />
        </div>

        <button
          onClick={() => navigate("/")}
          disabled={location.pathname === "/"}
          className="w-full hidden my-4 duration-500 disabled:hover:brightness-100 hover:brightness-75 md:block"
        >
          <span className="text-white text-center">
            Bem vindo&#40;a&#41;
            {auth.currentUser && (
              <>
                {<br />} {auth.currentUser.displayName}
              </>
            )}
            ! {getEmoji()}
          </span>
        </button>
      </div>

      <ul className="w-full flex items-center justify-around gap-2 md:flex-col md:gap-4 md:items-start md:px-4">
        <button
          type="button"
          onClick={() => navigate("/dashboard")}
          disabled={location.pathname === "/dashboard"}
          className="w-fit flex gap-2 items-center p-2 border border-transparent rounded-3xl 
          group duration-500 disabled:brightness-50 disabled:hover:brightness-100 disabled:pointer-events-none
          hover:border-easyGreen hover:bg-easyGreen/30 hover:shadow-[0_1px_5px_#1ce3c7] md:w-full"
        >
          <ChartSpline className="text-white" />
          <span className="hidden text-white text-xs duration-500 group-hover:ml-1 group-disabled:ml-1 md:block">
            Dashboard
          </span>
        </button>

        <button
          type="button"
          onClick={() => navigate("/reservations")}
          disabled={location.pathname === "/reservations"}
          className="w-fit flex gap-2 items-center p-2 border border-transparent rounded-3xl 
          group duration-500 disabled:brightness-50 disabled:hover:brightness-100 disabled:pointer-events-none 
          hover:border-easyGreen hover:bg-easyGreen/30 hover:shadow-[0_1px_5px_#1ce3c7] md:w-full"
        >
          <PiggyBank className="text-white" />
          <span className="hidden text-white text-sm duration-500 group-hover:ml-1 group-disabled:ml-1 truncate md:block">
            Reservas
          </span>
        </button>

        <button
          onClick={() => navigate("/add-movement")}
          disabled={location.pathname === "/add-movement"}
          className="block bg-easyGreen shadow-[0_-2px_5px_1px_#1ce3c7] rounded-full md:hidden"
        >
          <Plus size={40} strokeWidth={1.5} className="text-easyBlue" />
        </button>

        <button
          type="button"
          onClick={() => navigate("/easy-ai")}
          disabled={location.pathname === "/easy-ai"}
          className="w-fit flex gap-2 items-center p-2 border border-transparent rounded-3xl 
          group duration-500 disabled:brightness-50 disabled:hover:brightness-100 disabled:pointer-events-none 
          hover:border-easyGreen hover:bg-easyGreen/30 hover:shadow-[0_1px_5px_#1ce3c7] md:w-full"
        >
          <Bot className="text-white" />
          <span className="hidden text-white text-xs truncate max-w-[96px] duration-500 group-hover:ml-1 group-disabled:ml-1 md:block">
            Easy AI
          </span>
        </button>

        <button
          type="button"
          onClick={() => navigate("/add-movement")}
          disabled={location.pathname === "/add-movement"}
          className="w-fit hidden gap-2 items-center p-2 border border-transparent rounded-3xl 
          group duration-500 disabled:brightness-50 disabled:hover:brightness-100 disabled:pointer-events-none 
          hover:border-easyGreen hover:bg-easyGreen/30 hover:shadow-[0_1px_5px_#1ce3c7] md:w-full md:flex"
        >
          <HandCoins className="text-white" />
          <span className="hidden text-white text-xs duration-500 group-hover:ml-1 group-disabled:ml-1 md:block">
            Movimentação
          </span>
        </button>

        <button
          type="button"
          onClick={() => navigate("/profile")}
          disabled={location.pathname === "/profile"}
          className="w-fit flex gap-2 items-center p-2 border border-transparent rounded-3xl 
          group duration-500 disabled:brightness-50 disabled:hover:brightness-100 disabled:pointer-events-none 
          hover:border-easyGreen hover:bg-easyGreen/30 hover:shadow-[0_1px_5px_#1ce3c7] md:hidden"
        >
          <User className="text-white" />
        </button>
      </ul>

      <button
        type="button"
        onClick={() => handleLogout(navigate)}
        className="hidden text-red-500 left-7 absolute bottom-8 md:flex gap-2 items-center w-full group"
      >
        <LogOut />

        <span className="duration-500 group-hover:ml-1 group-disabled:ml-1">
          Sair
        </span>
      </button>
    </nav>
  );
}
