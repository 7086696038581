import { useState } from "react";
import FormInput from "./formInput";
import { handleRecoverPassword } from "../../utils/loginAndRegister";
import CustomButton from "../buttons/customButton";

export default function ForgotPassword({
  animationClassName,
  handleChangeForm,
}) {
  const [formData, setFormData] = useState({
    email: "",
  });

  const handleSubmit = async () => {
    handleRecoverPassword(formData.email);
  };

  return (
    <div
      className={`w-full flex flex-col gap-4 justify-center items-center ${animationClassName}`}
    >
      <h2 className="hidden font-bold text-xl text-easyBlue sm:block">
        Esqueceu sua senha?
      </h2>

      <form className="w-full max-w-xs flex flex-col justify-center items-center gap-4 mt-4">
        <FormInput
          id="email"
          title="E-mail"
          mandatory
          placeholder="Insira seu email"
          value={formData.email}
          setValue={(value) => setFormData({ ...formData, email: value })}
        />

        <CustomButton
          id="forgotSubmit"
          title="Enviar Email"
          functionToExecute={() => handleSubmit()}
        />

        <span className="text-xs text-easyYellow sm:text-easyBlack flex items-center gap-1">
          Lembra sua senha?
          <button
            type="button"
            onClick={() => handleChangeForm(0)}
            className="duration-500 underline underline-offset-2 hover:brightness-75"
          >
            Entrar
          </button>
        </span>
      </form>
    </div>
  );
}
