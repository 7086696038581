import React from "react";
import ApexChart from "../../../ApexChart";
import ChartContainer from "./chartContainer";

export default function ExpensesByMonth({ movements }) {
  // Array de categorias
  const categories = [
    "Alimentação",
    "Lazer",
    "Saúde",
    "Vestuário",
    "Mensalidades",
    "Transporte",
    "Pets",
    "Crédito",
    "Outros",
  ];

  // Função para filtrar os movimentos dos últimos meses
  function filterMovementsByMonth(movements) {
    const today = new Date();
    const fiveMonthsAgo = new Date();
    fiveMonthsAgo.setMonth(today.getMonth() - 5);

    return movements.filter((movement) => {
      const movementDate = new Date(movement.date);
      return (
        movementDate >= fiveMonthsAgo &&
        movementDate <= today &&
        movement.type === "Saída"
      );
    });
  }

  // Filtrar os movimentos dos últimos meses
  const movementsLastMonths = filterMovementsByMonth(movements);

  // Função para calcular o total por categoria mês a mês
  function calculateTotalByCategoryAndMonth(movements) {
    const totalByCategoryAndMonth = {};

    // Inicializar totalByCategoryAndMonth com estrutura vazia para cada mês e categoria
    for (let i = 0; i < 3; i++) {
      // selecionar limite de meses
      const month = new Date();
      month.setMonth(month.getMonth() - i);
      const monthKey = `${month.getFullYear()}-${(month.getMonth() + 1)
        .toString()
        .padStart(2, "0")}`;

      totalByCategoryAndMonth[monthKey] = {};

      categories.forEach((category) => {
        totalByCategoryAndMonth[monthKey][category] = 0;
      });
    }

    // Iterar pelos movimentos e somar o valor às categorias correspondentes
    movements.forEach((movement) => {
      const movementDate = new Date(movement.date + "T00:00:00-03:00");
      const monthKey = `${movementDate.getFullYear()}-${(
        movementDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}`;
      const category = movement.financialType;
      const value = parseFloat(movement.value);

      if (totalByCategoryAndMonth[monthKey] && categories.includes(category)) {
        totalByCategoryAndMonth[monthKey][category] += value;
      }
    });

    return totalByCategoryAndMonth;
  }

  // Calcular o total por categoria mês a mês nos últimos meses
  const totalByCategoryAndMonthLastMonths =
    calculateTotalByCategoryAndMonth(movementsLastMonths);

  const monthNames = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  // Formatar os meses no formato "Janeiro 2024" e ordenar de forma decrescente
  const monthsFormatted = Object.keys(totalByCategoryAndMonthLastMonths)
    .map((monthKey) => {
      const [year, month] = monthKey.split("-");

      return `${monthNames[parseInt(month, 10) - 1]} ${year}`;
    })
    .reverse(); // Inverter a ordem para que o mês atual seja o último

  // Preparar os dados para o gráfico
  const series = categories.map((category, index) => {
    return {
      name: category,
      data: monthsFormatted.map((month) => {
        const monthKey = `${month.split(" ")[1]}-${(
          "0" +
          (monthNames.indexOf(month.split(" ")[0]) + 1)
        ).slice(-2)}`;
        return {
          x: month,
          y: totalByCategoryAndMonthLastMonths[monthKey][category],
        };
      }),
    };
  });

  return (
    <ChartContainer title={"Despesas nos Últimos 3 Meses"}>
      <div className="w-full">
        <ApexChart
          categories={monthsFormatted}
          series={series}
          type="line"
          colors={[
            "#ffbd3d",
            "#00cf44",
            "#993399",
            "#ec0000",
            "#0A6847",
            "#FC4100",
            "#5a9cff",
            "#2D26B2",
            "#FF5BAE",
          ]}
          height="290px"
          hasLegend
        />
      </div>
    </ChartContainer>
  );
}
