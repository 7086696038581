import { useState } from "react";
import FormInput from "./formInput";
import CustomButton from "../buttons/customButton";
import { GoogleIcon } from "../extraIcons";
import { handleLogin, handleLoginGoogle } from "../../utils/loginAndRegister";
import { useNavigate } from "react-router-dom";

export default function SignIn({ animationClassName, handleChangeForm }) {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleGoogleLoginClick = async () => {
    setIsSubmitting(true);
    await handleLoginGoogle(navigate);
    setIsSubmitting(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    await handleLogin(formData.email, formData.password, navigate);
    setIsSubmitting(false);
  };

  const navigate = useNavigate();
  return (
    <div
      className={`w-full flex flex-col gap-4 justify-center items-center ${animationClassName}`}
    >
      <h2 className="hidden font-bold text-xl text-easyBlue sm:block">
        Entrar
      </h2>

      <form
        onSubmit={handleSubmit}
        className="w-full sm:max-w-xs flex flex-col justify-center items-center gap-4"
      >
        <FormInput
          id="email"
          title="E-mail"
          mandatory
          placeholder="Insira seu email"
          value={formData.email}
          setValue={(value) => setFormData({ ...formData, email: value })}
        />

        <FormInput
          id="password"
          title="Senha"
          password
          mandatory
          placeholder="Insira sua senha"
          value={formData.password}
          setValue={(value) => setFormData({ ...formData, password: value })}
        />

        <button
          type="button"
          onClick={() => handleChangeForm(2)}
          className="self-start text-xs text-easyYellow underline underline-offset-2 
          duration-500 hover:opacity-80 sm:text-easyBlack"
        >
          Esqueci minha senha
        </button>

        <CustomButton
          id="loginSubmit"
          title="Entrar"
          isSubmit
          disabled={isSubmitting}
        />

        <button
          type="button"
          onClick={() => handleChangeForm(1)}
          className="self-end text-xs text-easyYellow underline underline-offset-2
          duration-500 hover:brightness-75 sm:text-easyBlack"
        >
          Cadastrar-se
        </button>

        <div className="flex w-full max-w-[200px] items-center gap-2 mt-4 text-sm text-slate-600">
          <div className="h-px w-full bg-slate-300"></div>
          Ou
          <div className="h-px w-full bg-slate-300"></div>
        </div>

        <div className="w-full flex justify-center gap-4">
          <button
            type="button"
            onClick={handleGoogleLoginClick}
            disabled={isSubmitting}
            className="text-white bg-[#DB4437] font-medium rounded-lg text-sm p-3 
            text-center inline-flex items-center duration-500 hover:brightness-75 disabled:brightness-50"
          >
            <GoogleIcon />
          </button>

          {/* <button
            type="button"
            className="text-white bg-[#3b5998] font-medium rounded-lg text-sm p-3 
            text-center inline-flex items-center duration-500 hover:brightness-75"
          >
            <FacebookIcon />
          </button> */}
        </div>
      </form>
    </div>
  );
}
