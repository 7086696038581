import { useEffect, useState, useRef } from "react";
import Tooltip from "../../../tooltip";
import ApexChart from "../../../ApexChart";

export default function Evolution({ movements, isLoading }) {
  const [animatedPercentage, setAnimatedPercentage] = useState(0);
  const animationRef = useRef(null);

  const calculateBalances = (data) => {
    const balances = {},
      reserves = {},
      result = [];
    data.forEach(({ type, reservationType, value, date }) => {
      const month = date.startsWith("2024-") ? date.slice(0, 7) : null;
      if (!month) return;

      balances[month] ??= 0;
      reserves[month] ??= 0;
      const mult =
        type === "Entrada" ||
        (type === "Reserva" && reservationType === "Saída")
          ? 1
          : -1;
      balances[month] += mult * value;

      if (type === "Reserva")
        reserves[month] += (reservationType === "Entrada" ? 1 : -1) * value;
    });

    let total = 0,
      reserveTotal = 0;
    Object.keys(balances)
      .sort()
      .forEach((month) => {
        total += balances[month];
        reserveTotal += reserves[month];
        result.push({ month, balance: total + reserveTotal });
      });
    return result;
  };

  const balances = calculateBalances(movements);
  const evolution =
    balances.length < 2
      ? 0
      : ((balances.at(-1).balance - balances.at(-2).balance) /
          Math.abs(balances.at(-2).balance)) *
        100;

  const categories = balances
    .slice(-6)
    .map(({ month }) => month.replace("-", "/"));
  const seriesData = balances.slice(-6).map(({ balance }) => balance);

  useEffect(() => {
    if (isLoading) return setAnimatedPercentage(0);
    let start = null,
      duration = 300,
      startValue = 0;

    const animate = (t) => {
      if (!start) start = t;
      const progress = Math.min((t - start) / duration, 1);
      setAnimatedPercentage(startValue + (evolution - startValue) * progress);
      if (progress < 1) {
        animationRef.current = requestAnimationFrame(animate);
      }
    };

    animationRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(animationRef.current);
  }, [isLoading, evolution]);

  return (
    <div className="w-full relative">
      <div className="absolute top-8 left-[84px] z-[2]">
        <Tooltip message="Evolução em relação ao mês anterior" />
      </div>
      <ApexChart
        title={`${animatedPercentage.toFixed(2)}%`}
        titleColor={
          animatedPercentage >= 0 ? "rgb(34,197,94)" : "rgb(239,68,68)"
        }
        subtitle="Evolução"
        categories={categories}
        series={[{ name: "Montante Final", data: seriesData }]}
        type="line"
        curve="smooth"
        stroke={4}
        height="120px"
        colors={[animatedPercentage >= 0 ? "rgb(34,197,94)" : "rgb(239,68,68)"]}
        onlyChart
      />
    </div>
  );
}
