import { useEffect, useState } from "react";

export default function Manutention({ forecast }) {
  const calculateTimeLeft = () => {
    const futureDate = new Date(forecast * 1000);
    const currentDate = new Date();
    const difference = futureDate.getTime() - currentDate.getTime();
    if (difference <= 0) return { hours: "00", minutes: "00", seconds: "00" };
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / (1000 * 60)) % 60);
    const seconds = Math.floor((difference / 1000) % 60);
    return {
      hours: String(hours).padStart(2, "0"),
      minutes: String(minutes).padStart(2, "0"),
      seconds: String(seconds).padStart(2, "0"),
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  function formatDate(timestamp) {
    const data = new Date(timestamp * 1000);

    const dia = String(data.getDate()).padStart(2, "0");
    const mes = String(data.getMonth() + 1).padStart(2, "0");
    const ano = data.getFullYear();

    const horas = String(data.getHours()).padStart(2, "0");
    const minutos = String(data.getMinutes()).padStart(2, "0");

    return `${dia}/${mes}/${ano} às ${horas}:${minutos}`;
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const timeLeft = calculateTimeLeft();
      if (timeLeft.hours > 0 || timeLeft.minutes > 0 || timeLeft.seconds > 0) {
        setTimeLeft(timeLeft);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const { hours, minutes, seconds } = timeLeft;

  return (
    <div className="w-full h-full absolute top-0 left-0 z-[100] bg-black/85 overflow-hidden animation-from-none">
      <div className="w-full mx-auto relative overflow-hidden h-dvh flex items-center justify-center px-8">
        <div
          className="w-full max-w-md flex flex-col items-center justify-center gap-4
        bg-easyBlue relative max-h-dvh p-4 overflow-x-hidden text-center text-white rounded-2xl shadow-md"
        >
          <h2 className="text-2xl font-bold text-easyGreen">Manutenção</h2>

          <div className="w-[150px] sm:w-full sm:max-w-[200px]">
            <img
              src="/illustrations/manutention.gif"
              alt="Ilustração gráficos"
              className="w-full h-full object-cover"
            />
          </div>

          {/* <div className="font-bold text-lg text-easyGreen">
            {hours > 0 && <span>{hours}h </span>}
            <span>{minutes}m </span>
            <span>{seconds}s</span>
          </div> */}

          <p>
            Em breve estaremos de volta após a manutenção. Agradecemos sua
            compreensão.
          </p>

          <span className="text-sm text-white/80">
            Previsão de conclusão: {formatDate(forecast)}.
          </span>
        </div>
      </div>
    </div>
  );
}
