import FormInput from "./formInput";
import { useState } from "react";
import { handleRegister } from "../../utils/loginAndRegister";
import { useNavigate } from "react-router-dom";
import CustomButton from "../buttons/customButton";

export default function SignIn({ animationClassName, handleChangeForm }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const handleSubmit = async () => {
    setLoading(true);

    handleRegister(formData, navigate);
    setLoading(false);
  };

  return (
    <div
      className={`w-full flex flex-col gap-4 justify-center items-center ${animationClassName}`}
    >
      <h2 className="hidden font-bold text-xl text-easyBlue sm:block">
        Cadastre-se
      </h2>

      <form className="w-full max-w-xs flex flex-col justify-center items-center gap-2">
        <FormInput
          id="nome"
          title="Nome"
          mandatory
          placeholder="Insira seu nome"
          value={formData.name}
          setValue={(value) => setFormData({ ...formData, name: value })}
        />

        <FormInput
          id="email"
          title="E-mail"
          mandatory
          placeholder="Insira seu email"
          value={formData.email}
          setValue={(value) => setFormData({ ...formData, email: value })}
        />

        <FormInput
          id="password"
          title="Senha"
          password
          mandatory
          placeholder="Insira sua senha"
          value={formData.password}
          setValue={(value) => setFormData({ ...formData, password: value })}
        />

        <FormInput
          id="confirmPassword"
          title="Confirme sua senha"
          password
          mandatory
          placeholder="Confirme sua senha"
          value={formData.confirmPassword}
          setValue={(value) =>
            setFormData({ ...formData, confirmPassword: value })
          }
        />

        <button
          type="button"
          onClick={() => handleChangeForm(0)}
          className="my-2 self-end text-xs text-easyYellow underline underline-offset-2
          duration-500 hover:brightness-75 sm:text-easyBlack"
        >
          Já tenho uma conta
        </button>

        <CustomButton
          id="registerSubmit"
          title="Enviar"
          type="submit"
          disabled={loading && true}
          functionToExecute={() => handleSubmit()}
        />
      </form>
    </div>
  );
}
