import CustomButton from "../../../buttons/customButton";

export default function InitialDashboard({ height, selectPage }) {
  return (
    <div
      className={`w-full absolute z-50 top-0 left-0 bg-easyBlueLight flex flex-col gap-4 items-center justify-center px-4 ${height}`}
    >
      <h2 className="font-bold text-white text-2xl text-center">
        Bem-vindo ao nosso App!
      </h2>

      <p className="font-semibold text-white/80 text-sm max-w-lg text-center">
        Para iniciar, é necessário acessar a página &ldquo;Movimentação&ldquo; e
        inserir registros financeiros. Posteriormente, retorne a este local para
        visualizar os gráficos e demais recursos disponíveis.
      </p>

      <div className="sm:w-48">
        <CustomButton
          id="movimentacaoBtn"
          title="Movimentação"
          functionToExecute={() => selectPage(4)}
        />
      </div>
    </div>
  );
}
