import { X } from "lucide-react";

export default function Modal({
  title,
  toggleButton,
  onAccept,
  onDecline,
  children,
  isOpen,
  setIsOpen,
}) {
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      {/* Modal toggle button */}
      <div onClick={toggleModal}>{toggleButton}</div>

      {/* Main modal */}
      {isOpen && (
        <div
          tabIndex={-1}
          className="fixed top-0 right-0 left-0 z-[500] overflow-y-auto overflow-x-hidden bg-black/15 w-full 
          md:inset-0 h-dvh max-h-full flex justify-center items-center"
        >
          <div className="relative p-4 w-full max-w-2xl max-h-full">
            {/* Modal content */}
            <div className="relative bg-easyBlueLight rounded-lg shadow">
              {/* Modal header */}
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                <h3 className="text-xl font-semibold text-easyGreen">
                  {title}
                </h3>
                <button
                  onClick={toggleModal}
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-easyGreen rounded-lg 
                  text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                >
                  <X />
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* Modal body */}
              <div className="p-4 md:p-5 space-y-4">{children}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
