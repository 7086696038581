import { useEffect, useState, useRef } from "react";
import ApexChart from "../../../ApexChart";

export default function Reservation({ movements, isLoading }) {
  const [animatedBalance, setAnimatedBalance] = useState(0);
  const animationRef = useRef(null);

  const formatDateISO = (date) => date.toISOString().split("T")[0];
  const formatDateDDMM = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(2);
    return `${day}/${month}/${year}`;
  };

  const getLast7Days = () => {
    const daysISO = [];
    const daysFormatted = [];
    const today = new Date();
    for (let i = 6; i >= 0; i--) {
      const day = new Date(today);
      day.setDate(today.getDate() - i);
      daysISO.push(formatDateISO(day));
      daysFormatted.push(formatDateDDMM(day));
    }
    return { daysISO, daysFormatted };
  };

  const filteredMovements = Object.entries(movements)
    .map(([key, { date, type, reservationType, value }]) => ({
      date: formatDateISO(new Date(date)),
      type,
      reservationType,
      value: parseFloat(value),
    }))
    .filter(({ type }) => type === "Reserva")
    .sort((a, b) => a.date.localeCompare(b.date));

  const firstDay = new Date();
  firstDay.setDate(firstDay.getDate() - 6);
  const initialReservationBalance = filteredMovements.reduce(
    (balance, { date, reservationType, value }) => {
      if (date < formatDateISO(firstDay)) {
        const multiplier = reservationType === "Entrada" ? 1 : -1;
        return balance + multiplier * value;
      }
      return balance;
    },
    0
  );

  const calculateDailyReservationBalance = () => {
    const balances = {};
    let currentBalance = initialReservationBalance;
    const { daysISO, daysFormatted } = getLast7Days();

    daysISO.forEach((dayISO, index) => {
      filteredMovements.forEach(({ date, reservationType, value }) => {
        if (date.startsWith(dayISO)) {
          const multiplier = reservationType === "Entrada" ? 1 : -1;
          currentBalance += multiplier * value;
        }
      });
      balances[daysFormatted[index]] = currentBalance;
    });

    return balances;
  };

  const { daysFormatted } = getLast7Days();
  const balances = calculateDailyReservationBalance();
  const finalBalance = Object.values(balances).slice(-1)[0];

  const series = [{ name: "Saldo", data: Object.values(balances) }];

  useEffect(() => {
    if (isLoading) {
      setAnimatedBalance(0);
      return;
    }

    let start = null;
    const duration = 300;
    const startValue = 0;

    const animate = (timestamp) => {
      if (!start) start = timestamp;
      const progress = Math.min((timestamp - start) / duration, 1);
      setAnimatedBalance(startValue + (finalBalance - startValue) * progress);
      if (progress < 1) {
        animationRef.current = requestAnimationFrame(animate);
      }
    };

    animationRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(animationRef.current);
  }, [isLoading, finalBalance]);

  return (
    <ApexChart
      title={animatedBalance.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      })}
      subtitle={"Reserva"}
      categories={daysFormatted}
      series={series}
      type="line"
      curve={"smooth"}
      stroke={4}
      height={"120px"}
      colors={["#ffa900"]}
      onlyChart
    />
  );
}
