import { useState } from "react";
import { Eye, EyeOff } from "lucide-react";

export default function FormInput({
  id,
  title,
  placeholder,
  mandatory,
  password,
  value,
  setValue,
}) {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="w-full">
      <label
        htmlFor={id}
        className="block mb-2 text-sm font-medium text-white sm:text-easyBlue"
      >
        {title} {mandatory && <span className="text-red-500">*</span>}
      </label>
      <div
        className="relative overflow-hidden flex items-center bg-easyBlueLight border border-[#4c50a2] text-white text-sm rounded-lg w-full
        placeholder-gray-400 focus:outline-none sm:bg-transparent sm:border-easyBlue sm:text-gray-500
        "
      >
        <input
          type={password ? (showPassword ? "text" : "password") : "text"}
          id={id}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className={`px-2 py-2.5 border-none focus:outline-none bg-transparent ${
            password ? "w-[calc(100%-56px)]" : "w-full"
          }`}
          placeholder={placeholder}
          {...(mandatory ? { required: true } : null)}
        />

        {password && (
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="absolute py-2 px-4 top-0 right-0 duration-500 text-easy z-10"
          >
            {showPassword ? <EyeOff /> : <Eye />}
          </button>
        )}
      </div>
    </div>
  );
}
