export default function ProfileInfo({ label, value }) {
  return (
    <div className="truncate text-white max-w-40">
      <span className="truncate font-semibold text-easyGreen text-xs block pb-1">
        {label}
      </span>

      <span>{value || "-"}</span>
    </div>
  );
}
